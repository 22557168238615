import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Avatar as FlowbiteAvatar} from 'flowbite-react';
import customTheme from '../../Utils/flowbite-theme';

export const ReusableAvatar = memo(({ src, className, customTheme }) => {
  return (
    <div className={`"flex flex-wrap gap-2 ${className} "`}>
      <FlowbiteAvatar img={src} alt="image" rounded theme={customTheme} />
    </div>
  );
});

ReusableAvatar.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  customTheme:PropTypes.object,
};

ReusableAvatar.defaultProps = {
  src: "",
  className: "",
  customTheme:customTheme
};

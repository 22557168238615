import React, { useState, } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    DashboardIcon,
    RefreshIcon,
    RepeatIcon,
    GiftIcon,
    DollarSignIcon,
    Logo,
    LogoSmall,
    MenuIcon,
} from '../../../Theme/icons';
import { Chips } from '../../../Components';
import { SideBar } from '../../../Components/SideBar';
import { useSelector } from 'react-redux';
import ProfileMenu from '../../../Components/ProfileMenu';
import CartIcon from '../../../Components/CartIcon';
import MyCartDrawer from '../../../Components/MyCartDrawer';

const ProjectPageHeader = () => {

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const { user } = useSelector((state) => state.auth) || { user: null }

    const navigate = useNavigate();

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const handleToggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const sidebarMenuItems = [
        { label: 'Overview', icon: <DashboardIcon />, url: 'overview' },
        {
            label: 'Updates',
            icon: <RefreshIcon />,
            url: 'updates',
            chip: (
                <Chips
                    variant="info"
                    label="Post"
                    className="px-2.5 py-1.5 inline-flex items-center rounded-md text-white hover:text-white text-small-button-text cursor-pointer"
                    onClick={() => navigate('updates?is_creating=true')}
                />
            )
        },
        { label: 'Donations', icon: <DollarSignIcon />, url: '/project-donations' },
        { label: 'Payment/Transfers', icon: <RepeatIcon />, url: '/project-payments' },
        { label: 'Rewards', icon: <GiftIcon />, url: 'rewards' },
    ];


    return (
        <div>
            <div className="fixed z-10 flex justify-between items-center w-full h-16 md:h-20 bg-white border-b border-solid border-neutral-300 px-4 py-5 md:px-7.5 md:py-6">
                <div className="block md:hidden">
                    <MenuIcon color='#1A0B00' onClick={toggleSidebar} />
                </div>
                <div className='md:hidden'>
                    <Link to="/home" aria-label='Navigate to Dashboard'>
                        <LogoSmall />
                    </Link>
                </div>
                <div className="hidden md:block">
                    <Link to="/home" aria-label='Navigate to Dashboard'>
                        <Logo />
                    </Link>
                </div>
                <div className="flex items-center justify-between gap-3 md:gap-6 xl:gap-11">
                    <div>
                        <CartIcon onClick={handleToggleDrawer} />
                        <MyCartDrawer isOpen={isDrawerOpen} onClose={handleToggleDrawer} />
                    </div>
                    {user !== null && <ProfileMenu />}
                </div>
            </div>
            {isSidebarOpen && <SideBar items={sidebarMenuItems} toggleSidebar={toggleSidebar} />}
        </div>
    );
};

export default ProjectPageHeader;

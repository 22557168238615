import React, { useState } from 'react';
import FacebookLogin from '../../../../Components/FacebookLogin';
import GoogleLogin from '../../../../Components/GoogleLogin';
import { Button, Typography } from '../../../../Components';
import { CreditCardIcon } from '../../../../Theme/icons';
import CreditCardForm from './CreditCardForm';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UnkownCheckout } from './UnkownCheckout';
import { PaypalCheckout } from './PaypalCheckout';
import { updateUnknownCheckoutUser } from '../../../../Redux/cartReducer';


const CheckoutAuth = ({ setCurrentStep, currentStep, hidePrevButton, toggleModal, isPaymentCompleted, updatePaymentStatus, updateProjectOverviews = () => null, isAnonymous = false }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [unknownUserData, setUnknownUserData] = useState({})
    const { user = null } = useSelector(state => state?.auth) || { user: null };
    const { cartItems } = useSelector(state => state?.cart) || { cartItems: [] }
    const [selctedPaymnetMethod, setSelctedPaymnetMethod] = useState("")

    const chooseMode = (mode) => {
        setSelctedPaymnetMethod(mode)
    };

    const handleSignInClick = () => {
        navigate('/login')
    }

    const cartItemsHavemixedPaymentTypes = !!cartItems?.length && cartItems?.some(cartItem => cartItem?.isRecurring) && !!cartItems?.some(cartItem => !cartItem?.isRecurring)
 
    const ChooseMethod = () => {
        return (<div className="flex flex-col gap-6">
            {user === null &&
                <div className='flex flex-col gap-5'>
                    <Button
                        type="button"
                        variant="primary"
                        label="Sign In"
                        iconClassName="hidden"
                        className="w-full"
                        onClick={handleSignInClick}
                    />
                    <GoogleLogin />
                    <FacebookLogin />
                </div>}
            {user === null && <div className='flex items-center justify-between gap-3 font-normal tracking-tight text-neutral-600 text-sm/4'>
                <span className='line'></span>or<span className='line'></span>
            </div>}
            <div className='flex flex-col gap-4'>
                {user === null && <Typography variant="heading7" className="text-center text-neutral-800" text="Continue as Guest" />}   <div className='flex flex-col gap-5'>
                    {user && <span className='flex items-end gap-2 justify-center !font-normal text-heading-7'>
                        Signed in as <Typography variant="heading7" className="text-center text-neutral-800 capitalize" text={` ${user?.firstName} ${user?.lastName}`} />
                    </span>}
                    <Typography variant="heading7" className="text-center text-neutral-800" text="Select Payment Method" />
                    {(user !== null && !isAnonymous && !cartItemsHavemixedPaymentTypes) && <PaypalCheckout toggleModal={toggleModal} chooseMode={chooseMode} selctedPaymnetMethod={selctedPaymnetMethod} updatePaymentStatus={updatePaymentStatus} isPaymentCompleted={isPaymentCompleted} />}

                    <Button
                        type="button"
                        onClick={() => chooseMode("card")}
                        variant="neutral"
                        label="Debit or credit card"
                        icon={<CreditCardIcon />}
                        className="w-full"
                    />
                </div>
            </div>
        </div>)
    }

    const updateUnkownUserData = (data) => {
        setUnknownUserData(data);
        dispatch(updateUnknownCheckoutUser(data))
    }

    const PaythroughCard = () => {
        return (<div className='flex flex-col gap-4'>
            {user && <span className='flex items-end gap-2 justify-center !font-normal text-heading-7'>
                Signed in as <Typography variant="heading7" className="text-center text-neutral-800 capitalize" text={` ${user?.firstName} ${user?.lastName}`} />
            </span>}
            <Typography variant="heading7" className="text-center text-neutral-800" text="Select Payment Method" />
            <div className='flex flex-col gap-5'>
                {user === null && !Object.keys(unknownUserData)?.length ? <UnkownCheckout updateProjectOverviews={updateProjectOverviews} updateUnkownUserData={updateUnkownUserData} updatePaymentStatus={updatePaymentStatus} isPaymentCompleted={isPaymentCompleted} /> :
                    <CreditCardForm isAnonymous={isAnonymous} updateProjectOverviews={updateProjectOverviews} updatePaymentStatus={updatePaymentStatus} isPaymentCompleted={isPaymentCompleted} />}
            </div>
        </div>)
    }


    return (
        <div>
            {selctedPaymnetMethod === "card" ? <PaythroughCard /> : <ChooseMethod />}
            <div className='flex flex-col gap-5'>
                {hidePrevButton ? <Button
                    variant="primary-text"
                    label="Close"
                    iconClassName="hidden"
                    className="w-full"
                    onClick={() => toggleModal()}
                /> : <Button
                    variant="primary-text"
                    label="Back"
                    iconClassName="hidden"
                    className="w-full"
                    onClick={() => setCurrentStep(currentStep - 1)}
                />}
            </div>
        </div >
    );
};

export default CheckoutAuth;

import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { SearchIcon } from '../../Theme/icons';

export const Search = memo(({ placeholder, handleChange, className ,search }) => {
    const [searchTerm, setSearchTerm] = useState(search || '');

    const debouncedHandleChange = debounce((value) => {
        handleChange(value?.toLowerCase());
    }, 300);

    const handleInputChange = (event) => {
        const { value } = event.target;
        setSearchTerm(value);
        debouncedHandleChange(value);
    };

    useEffect(() => {
        setSearchTerm(search)
    }, [search])
    return (

        <div className={`relative px-4 py-3 border border-neutral-500 rounded-lg h-11 flex items-center text-neutral-600  ${className}`}>
            <span className='absolute left-4'><SearchIcon /></span>
            <label htmlFor="search" className='sr-only'>Search</label>
            <input
                id="search"
                type="search"
                placeholder={placeholder}
                onChange={handleInputChange}
                value={searchTerm}
                className='flex-1 outline-none pl-7'
            />
        </div>
    );
});

Search.propTypes = {
    placeholder: PropTypes.string,
    handleChange: PropTypes.func,
    className: PropTypes.string,
};

Search.defaultProps = {
    placeholder: "Search",
    handleChange: () => { },
    className: "",
};

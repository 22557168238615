import { gql } from '@apollo/client';

export const FACEBOOK_MUTATION = gql`
mutation Mutation($facebookId: String!, $firstName: String!, $lastName: String!) {
    facebookAuth(facebookId: $facebookId, firstName: $firstName, lastName: $lastName) {
      token
      profile {
      id
      firstName
      lastName
      displayName
      email
      role
      status
      profileImage
      defaultCurrency
      }
    }
  }`
import React, { memo, useEffect, } from 'react';
import PropTypes from 'prop-types';

export const ModalsComponent = memo(({ id, title, content, showModal, toggleModal, className, titleClassName }) => {

  useEffect(() => {
    let timeoutId;
    
    if (showModal) {
      timeoutId = setTimeout(() => {
        document.body.style.overflow = 'hidden';
      }, 100);
    } else {
      document.body.style.overflow = 'auto';
    }
    
    return () => {
      clearTimeout(timeoutId);
      document.body.style.overflow = 'auto';
    };
  }, [showModal]);
  
  return (
    <>
      {showModal && (
        <div
          id={id}
          className="fixed top-0 left-0 right-0 z-50 flex items-center justify-center bg-gray-900 h-dvh max-sm:items-end max-sm:place-content-end bg-opacity-40"
        >
          <div className={`w-full sm:max-w-140 bg-white rounded-lg max-sm:rounded-b-none py-5 px-4 max-h-90vh md:my-12 overflow-y-auto md:p-10 ${className}`}>
            <div className={`relative flex justify-between items-start ${ title ? 'pb-5' : 'pb-0'}`}>
              <h3 className={`font-bold text-heading-6 md:text-heading-5 text-neutral-1000 ${titleClassName}`}>{title}</h3>
              <button
                onClick={toggleModal}
                className="md:absolute -top-2 -right-2 md:-top-4 md:-right-4"
                type="button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"  
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <rect width="24" height="24" fill="#FBF9F7" rx="2"></rect>
                  <path
                    stroke="#1A0B00"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M18 6L6 18M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <div>
              {content}
            </div>
          </div>
        </div>
      )}
    </>
  );
});

ModalsComponent.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  showModal: PropTypes.bool,
  toggleModal: PropTypes.func,
  titleClassName: PropTypes.bool.isRequired,
  className:PropTypes.string,
};

ModalsComponent.defaultProps = {
  showModal: false,
  toggleModal: () => { },
  className:"",
};

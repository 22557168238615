import React, { Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import GET_PROFILE from "../Actions/Auth/GetProfile";
import { setLoggedInUser } from "../Redux/reducer";
import { useQuery } from "@apollo/client";
import LoaderAnimation from "../Components/Loader";
import ProtectedRoute from "./ProtectedRoute";
import allRoutes from "./allRoutes";

function expandRoutes(routes) {
   
  
  return !!routes?.length && routes?.map((i,index) => (
    <React.Fragment key={`${i?.title} ${index}`}>
      <Route
        path={i.path}
        exact={i.exact || false}
        title={i.title}
        element={
          <ProtectedRoute
            redirectUser={i.redirectUser}
            redirectAdmin={i.redirectAdmin}
            redirectNoAuth={i.redirectNoAuth}
          >
            {i.element || <Outlet />}
          </ProtectedRoute>
        }
      >
        {i.children?.length ? expandRoutes(i.children) : null}
      </Route>
    </React.Fragment>
  ));
}

const Routing = () => {
  const dispatch = useDispatch();

  const { data, error, loading, refetch } = useQuery(GET_PROFILE);

  const initializing = useSelector((state) => state.auth.initializing);

  useEffect(() => {
    if (error && error.message === "Forbidden") {
      dispatch(setLoggedInUser({ profile: null, token: null }));
      localStorage.removeItem("token");
      localStorage.removeItem("user");
    } else if (data) {
      dispatch(setLoggedInUser({ profile: data.getProfile }));
    }
    //eslint-disable-next-line
  }, [loading, data, error]);

  useEffect(() => {
    const listener = () => refetch();
    window.addEventListener("storage", listener);
    return () => window.removeEventListener("storage", listener);
  }, []);

  if (initializing) return <LoaderAnimation />;
  return (
    <Router>
      <Suspense fallback={<LoaderAnimation />}>
        <Routes>{expandRoutes(allRoutes)}</Routes>
      </Suspense>
    </Router>
  );
};

export default Routing;

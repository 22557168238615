import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

export const Seo = memo(({ title, description, keywords, author }) => {
  return (
    <Helmet >
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="stylesheet" type="text/css" href={`styles/.css`} />
      <meta name="author" content={author} />
    </Helmet>
  );
});

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  author: PropTypes.string
};

Seo.defaultProps = {
  title: 'Default Title',
  description: 'Default description',
  keywords: 'react, SEO',
  author: "ATeam SoftSolutions"
};



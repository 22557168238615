import React, { useState } from 'react'
import { Logo, LogoSmall, MenuIcon } from '../../../Theme/icons' 
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProfileMenu from '../../../Components/ProfileMenu';
import CartIcon from '../../../Components/CartIcon';
import MyCartDrawer from '../../../Components/MyCartDrawer'; 

const ProjectDetailsPageHeader = () => {
    const { user } = useSelector((state) => state.auth) || { user: null }

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const handleToggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className="z-10 flex justify-between items-center w-full h:16 md:h-20 bg-white border-b border-solid border-neutral-300 px-4 py-5 md:px-7.5 md:py-6">
            <div className="block md:hidden">
                <MenuIcon color='#1A0B00' onClick={toggleSidebar} />
                {/*isSidebarOpen && <ProjectPagesSidebar className={`${isSidebarOpen ? '-translate-x-[400px]' : 'translate-x-0'} transition-all ease-in delay-500`} />*/}
            </div>
            <div className='md:hidden'>
                <Link to="/home" aria-label='Navigate to Dashboard'><LogoSmall /></Link>
            </div>
            <div className="hidden md:block">
                <Link to="/home" aria-label='Navigate to Dashboard'><Logo /></Link>
            </div>
            <div className="flex items-center justify-between gap-3 md:gap-6 xl:gap-11">
                 <div>
                    <CartIcon onClick={handleToggleDrawer} />
                    <MyCartDrawer isOpen={isDrawerOpen} onClose={handleToggleDrawer} />
                </div>
                {user !== null && <ProfileMenu />}
            </div>
        </div>
    )
}

export default ProjectDetailsPageHeader

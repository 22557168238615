import React, { useState } from 'react'
import { Dropdown } from '../Dropdown'
import { ChevronDownIcon, CreditCardIcon, DollarSignIcon, FileTextIcon, LogoutIcon, UserIcon } from '../../Theme/icons'
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser } from '../../Redux/reducer'
import { useMutation } from '@apollo/client'
import { LOGOUT } from '../../Actions/Auth/ConfirmForgotMutation'
import { useShowToast } from '../../Utils/toast'
import { ModalsComponent } from '../Modals'
import Button from '../Button'

const ProfileMenu = () => {
    const showToast = useShowToast();
    const dispatch = useDispatch();
    const { user } = useSelector(state => state?.auth) || { user: '' }
    const [logoutModal, setLogoutModal] = useState(false)
    const { firstName, lastName, profileImage = '' } = user || { firstName: '', lastName: '', profileImage: "" }
    const [logout, { loading }] = useMutation(LOGOUT);
    const { profile } = JSON.parse(localStorage?.getItem('user') || `{}`)

    const handlesConfirm = async () => {
        try {
            const { data } = await logout()
            if (data) {
                dispatch(logoutUser())
                showToast({ message: "logged out Successfully", variant: 'success' })
                localStorage.removeItem('token');
                localStorage.removeItem('user')
                window.location.href = '/home';
            }
            setLogoutModal(false)
        } catch (error) {
            showToast({ message: error.message, variant: 'error' });
        }
    }

    const handlesCancel = async () => setLogoutModal(false);

    const handleLogout = async (e) => {
        e.preventDefault();
        setLogoutModal(true)
    }

    const menuItems = [
        { href: '/account', text: 'My Account', icon: <UserIcon size={24} /> },
        { href: '/my-projects', text: 'My Projects', icon: <FileTextIcon /> },
        { href: '/my-donations', text: 'My Donations', icon: <DollarSignIcon /> },
        { href: '/my-cards', text: 'My Cards', icon: <CreditCardIcon /> },
        { click: handleLogout, type: "button", text: 'Logout', icon: <LogoutIcon /> }
    ];

    return (
        <>
            <Dropdown loading={loading}
                label={`${firstName} ${lastName}`}
                menuItems={menuItems}
                chevronDownIcon={<ChevronDownIcon />}
                imgSrc={profileImage || profile?.profleimage || "/assets/images/avatar.png"}
                hoverClassName="md:hover:bg-primary-100"
            />
            <ModalsComponent
                size="lg"
                showModal={logoutModal}
                toggleModal={handlesCancel}
                content={
                    <div className="text-center">
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            Are you sure you want to logout?
                        </h3>
                        <div className="flex justify-center gap-4">
                            <Button className="bg-red-700" disabled={loading} variant="primary-outline" onClick={handlesCancel} label={"No, cancel"} />
                            <Button className="bg-red-700" disabled={loading} loaderText={"Logging out..."} loader={loading} variant="primary" onClick={handlesConfirm} label={"Yes, Logout"} />
                        </div>
                    </div>
                }
            />
        </>
    )
}

export default ProfileMenu

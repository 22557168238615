import axios from 'axios';
import React, {useState, useEffect} from 'react'
import { FieldRequired } from '../FieldRequired';


const fetchCities = async (search) => {
    const response = await axios.post('https://places.googleapis.com/v1/places:searchText', {textQuery: search, pageSize: 10}, {headers: {
        'Content-Type': 'application/json',
        'X-Goog-Api-Key': process.env.REACT_APP_GOOGLE_API_KEY,
        'X-Goog-FieldMask': 'places.id,places.formattedAddress,places.addressComponents,places.location'
    }});
    return response.data.places;
};

export function LocationSelect({handleSelect=()=>{}, value ,label}) {
    const [dropdown, setDropdown] = useState(false);
    const [list, setList] = useState([]);
    const [address, setAddress] = useState('');
    const search = txt => {
        setAddress(txt)
        if(!txt) {
            setList([])
            return;
        }
        fetchCities(txt).then(cities => {
            if(cities) setList(cities);
        }).catch(e => {
            setList([])
            console.error(e)
        })
    }
    const onSelect = city => {
        const address = city.addressComponents.filter(i => !i.types.includes('postal_code') && !i.types.includes('country')).map(i => i.longText).join(', ');
        setAddress(address)
        const country = city.addressComponents.find(i => i.types.includes('country'));
      
        handleSelect({
            address,
            country: country?.shortText,
            countryLabel: country?.longText,
            zip: city.addressComponents.find(i => i.types.includes('postal_code'))?.longText,
            location: city.location,
            id: city.id,
        })
        setDropdown(false)
    }
    useEffect(() => {
        if(value)
            setAddress(value.address)
    }, [value])
    return (
        <div className="relative">
            <div className={`${dropdown?"block":"hidden"} fixed z-50 top-0 left-0 right-0 bottom-0`} onClick={() => setDropdown(false)} />
            <label className="block mb-2 font-normal text-sm/4 text-neutral-1000" htmlFor="locationSearch">{label || 'Location'}<FieldRequired /></label>
            <input
                type="text"
                onChange={e => search(e.target.value)}
                value={address}
                onFocus={() => setDropdown(true)}
                className={`input ${dropdown?"z-50 relative":""}`}
                placeholder="Enter an Address"
                id="locationSearch"
            />
            <div className={`${dropdown?"absolute":"hidden"} w-full z-50 bg-white max-h-[30vh] overflow-auto shadow-xl`}>
                {list.map(city => (
                    <div
                        key={city.id}
                        className="bg-white px-4 py-2 border-b-2 border-x-2 border-neutral-500 cursor-pointer text-sm hover:bg-primary-100"
                        onClick={() => onSelect(city)}
                    >{city.formattedAddress}</div>
                ))}
            </div>
        </div>
    )
}

import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import GET_PROJECT_OVERVIEW from '../../Actions/Auth/GetProjectOverview';
import { Outlet, useLocation, useParams, useNavigate, Navigate } from 'react-router-dom';
import ProjectPageHeader from '../../Layouts/Header/ProjectPage';
import { SideBar } from '../../Components/SideBar';
import { DashboardIcon, DollarSignIcon, GiftIcon, RefreshIcon, RepeatIcon } from '../../Theme/icons';
import { Chips } from '../../Components/Chips';
import { OverviewContext } from './overviewData';
import LoaderAnimation from '../../Components/Loader';
import { useSelector } from 'react-redux';
import { useShowToast } from '../../Utils/toast';

export default function Template() {
    const { projectid: id } = useParams();
    const showToast = useShowToast();
    const { user = null } = useSelector(state => state?.auth) || { user: null };
    const { data, loading, error, refetch } = useQuery(GET_PROJECT_OVERVIEW, { fetchPolicy: "no-cache", variables: { slug: id }, skip: !id }) || { data: {} };

    const { getProjectOverview = {} } = data || { getProjectOverview: {} }
    const { project = {} } = getProjectOverview || { project: {} }
    const { ownerDetails } = project || { ownerDetails: {} }

    useEffect(() => {
        if (error && error?.message) {
            showToast({ message: error?.message, variant: 'error' })
        }
        //eslint-disable-next-line
    }, [error])

    return (loading ? <LoaderAnimation /> :
        ((ownerDetails && String(ownerDetails?.id) !== String(user?.id)) || error) ? <Navigate to="/no-permission" /> :
            <TemplateOverviewProtectedRoute project={project} refetch={refetch} getProjectOverview={getProjectOverview} />)

}


const TemplateOverviewProtectedRoute = ({ project = {}, getProjectOverview = {}, refetch = () => null }) => {
    const [openSidebar, setOpenSidebar] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { projectid: id } = useParams();
    const activePath = location.pathname.split('/').filter(i => i).pop();

    const items = [
        { label: 'Overview', icon: <DashboardIcon />, url: 'overview' },
        { label: 'Updates', icon: <RefreshIcon />, url: 'updates', chip: <Chips variant="info" label="Post" className="px-2.5 py-1.5 inline-flex items-center rounded-md text-white hover:text-white text-small-button-text cursor-pointer" onClick={(e) => navigate('updates?show-form=true')} /> },
        { label: 'Donations', icon: <DollarSignIcon />, url: 'donations' },
        { label: 'Payment/Transfers', icon: <RepeatIcon />, url: 'payments' },
        { label: 'Rewards', icon: <GiftIcon />, url: 'rewards' },
    ];

    const fetchOverviewData = async () => {
        await refetch({
            variables: { slug: id }
        })
    }


    return (
        <div className="relative h-screen overflow-hidden">
            <header>
                <ProjectPageHeader projectTitle="Masjid Abu Bakr Al-Siddeeq" openSidebar={() => setOpenSidebar(true)} />
            </header>
            <main className="flex h-full pt-20">
                <aside className={`w-67.5 hidden md:block md:fixed ${openSidebar ? '-translate-x-[400px]' : 'translate-x-0 transition-all ease-in delay-500 md:transition-x-0'}`}>
                    <SideBar
                        items={items.map(i => ({ ...i, active: activePath === i.url }))}
                        className="w-full md:fixed"
                        projectTitle={project?.name} />
                </aside>
                <section className="flex-1 overflow-auto md:ml-68">
                    <OverviewContext.Provider value={{ ...getProjectOverview, fetchOverviewData: fetchOverviewData }}>
                        <Outlet />
                    </OverviewContext.Provider>
                </section>
            </main>
        </div>
    );
}
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { GENERATE_PAYMENT_CERTIFICATE } from '../../../../GraphQL/Queries/payment';
import { Button, Typography } from '../../../../Components';
import { useShowToast } from '../../../../Utils/toast';
import ShareWithFriends from './ShareWithFriends';


const DonationCompleted = ({ isPaymentCompleted }) => {
    const showToast = useShowToast();
    const [certificateURL, setcertificateURL] = useState('')

    const { user = null } = useSelector(state => state?.auth) || { user: null }
    const { email = null } = user || { email: null }
    const { donationIds = [] } = isPaymentCompleted || { donationIds: [] }

    const firsetDonationID = (donationIds?.length && donationIds?.at(0)) || null

    const { data, loading: fetchCertificatesloader } = useQuery(GENERATE_PAYMENT_CERTIFICATE, {
        fetchPolicy: "no-cache",
        variables: { generateDonationCertificateId: Number(firsetDonationID) },
        skip: !Number(firsetDonationID)
    })

    const { generateDonationCertificate } = data || { generateDonationCertificate: "" }

    const generateCertificateURLs = async () => {
        if (certificateURL) {
            var link = document.createElement('a');
            link.href = certificateURL
            link.download = 'crtificate.pdf';
            link.target = '_blank';
            link.click();
        } else {
            showToast({ message: 'Sorry!. something went wrong', variant: 'error' })
        }
    }

    useEffect(() => {
        setcertificateURL(generateDonationCertificate)
    }, [generateDonationCertificate])

    return (
        <div className="flex flex-col items-center gap-5">
            <div className='space-y-2.5 w-full'>
                <img className='mx-auto' src="/assets/images/donation.svg" alt="Donation" />
                <div className="space-y-4 text-center">
                    <Typography variant="heading5" className="text-neutral-1000 max-md:text-heading-6" text="Thank you for your donation" />
                    <p className='mx-auto text-medium-text text-neutral-1000 w-60'>
                        A receipt and certificate has been sent to <span className='font-bold'>{email || "Anonymous user"}</span>
                    </p>
                </div>
            </div>
            <Button
                variant="primary-outline"
                label="View Certificate"
                loader={fetchCertificatesloader}
                disabled={fetchCertificatesloader}
                loaderText="Generating Certificate..."
                onClick={generateCertificateURLs}
                iconClassName="hidden"
                className="w-full"
            />
            <ShareWithFriends url={certificateURL} />
        </div>
    );
};




export default DonationCompleted;

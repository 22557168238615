const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry).catch(error => console.error('CLS error:', error));
      getFID(onPerfEntry).catch(error => console.error('FID error:', error));
      getFCP(onPerfEntry).catch(error => console.error('FCP error:', error));
      getLCP(onPerfEntry).catch(error => console.error('LCP error:', error));
      getTTFB(onPerfEntry).catch(error => console.error('TTFB error:', error));
    }).catch(error => console.error('Import error:', error));
  }
};

export default reportWebVitals;

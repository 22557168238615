import React, { memo } from 'react';
import PropTypes from 'prop-types';
import customTheme from '../../Utils/flowbite-theme';
const ChipsClasses = {
  success: 'bg-green-300',
  error: 'bg-red-300',
  info: 'bg-primary-300',
  warning: 'bg-yellow-300',
};

const getChips = (variant) => ChipsClasses[variant] || ChipsClasses.default;

export const Chips = memo(({ variant, label, className,customTheme, onClick }) => {
 
  const classes = ` ${getChips(variant)} ${className}`
  return (
    <div className={classes} style={customTheme} onClick={onClick} >
      {label}
      
    </div>
  );
});

Chips.propTypes = {
  variant: PropTypes.oneOf([ 'success', 'danger','warning','info']),
  label: PropTypes.string,
  className:PropTypes.string,
  customTheme:PropTypes.object,
  onClick: PropTypes.func
};

Chips.defaultProps = {
  variant: 'success',
  label: 'success', 
  className:" ",
  customTheme:customTheme,
  onClick: () => {}, 
  
};

import React, { lazy } from 'react';
import ProtectedNavigate from '../Components/ProtectedNavigate';
import Template from '../Pages/Project/OverviewTemplate';

const ProjectOverview = lazy(() => import('../Pages/Project/Overview/index'));
const ProjectUpdates = lazy(() => import('../Pages/Project/Updates/index'));
const ProjectRewards = lazy(() => import('../Pages/Project/Rewards/index'));
const ProjectPayments = lazy(() => import('../Pages/Project/Payments/index'));
const ProjectDonations = lazy(() => import('../Pages/Project/Donations/index'));
const ProjectEdit = lazy(() => import('../Pages/Project/Overview/EditOverviewPage/index'));

const overviewRoutes = {
	path: "/my-projects/:projectid",
	element: <Template />, 
	title: 'Project Overview',
	redirectNoAuth: '/login',
	children: [
		{
			path: 'donations',
			title: 'Project Donations',
			element: <ProjectDonations />,
		},
		{
			path: 'payments',
			title: 'Project Payments/Transfers',
			element: <ProjectPayments />,
		},
		{
			path: 'updates',
			title: 'Project Updates',
			element: <ProjectUpdates />,
		}, {
			path: 'rewards',
			title: 'Project Rewards',
			element: <ProjectRewards />
		}, {
			path: 'overview',
			title: 'Project Overview',
			element: <ProjectOverview />
		}, {
		}, {
			path: 'edit',
			title: 'Project edit',
			element: <ProjectEdit />
		}, {
			path: '',
			exact: true,
			element: <ProtectedNavigate to="overview" />,
		}]
};

export default overviewRoutes;

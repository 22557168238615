import { lazy } from 'react';
import { AuthenticatedRoute } from './AuthenticatedRoute';
import CheckRouteParams from './CheckRouteParams';
import PayPalCompleted from '../Pages/Project/ProjectDetails/Checkout/PayPalCompleted';

const MyAccount = lazy(() => import('../Pages/UserSettings/MyAccount/index'));
const MyProjects = lazy(() => import('../Pages/UserSettings/MyProjects/index'));
const MyDonations = lazy(() => import('../Pages/UserSettings/MyDonations/index'));
const MyCards = lazy(() => import('../Pages/UserSettings/MyCards/index'));
const CreateProject = lazy(() => import('../Pages/CreateProject/index'));
const EditOverviewPage = lazy(() => import('../Pages/Project/Overview/EditOverviewPage/index'));

const routes = [{
    path: "/account",
    element: <MyAccount />,
    title: 'Account',
    redirectNoAuth: '/login',
},
{
    path: "/my-projects",
    element: <MyProjects />,
    title: 'My Projects',
},
{
    path: "/my-donations",
    element: <MyDonations />,
    title: 'My Donations',
},
{
    path: "/my-cards",
    element: <MyCards />,
    title: 'My Cards',
},
{
    path: "/create-project",
    element: <CreateProject />,
    title: 'Create Project',
    redirectNoAuth: '/login'
},
{
    path: "/edit-project",
    element: <CheckRouteParams><EditOverviewPage /></CheckRouteParams>,
    title: 'Project Overview - Edit',

},
{
    path: "/thank-you",
    element: <PayPalCompleted />,
    title: 'Payment Completed',
   
},
]


export const settingsRoutes = !!routes?.length && routes?.map(route => ({
    ...route,
    element: <AuthenticatedRoute>{route?.element} </AuthenticatedRoute>
}))
import React, { useEffect, useState } from 'react';
import { useStripe, useElements, PaymentElement, } from '@stripe/react-stripe-js';
import { Button, Typography } from '../../../../Components';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useMutation } from '@apollo/client';
import { GET_CLIENT_SECRET, GET_UNKNOWN_CHECKOUT } from '../../../../GraphQL/Queries/payment';
import { useSelector } from 'react-redux';
import { useShowToast } from '../../../../Utils/toast'; 
import { SpinnerPrimaryIcon } from '../../../../Theme/icons';  

const CreditCardForm = ({ isPaymentCompleted, updatePaymentStatus, updateProjectOverviews = () => null, isAnonymous = false }) => {
    
    const key = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    const stripePromise = loadStripe(key);
    const showToast = useShowToast();
    const [donationIds, setDonationIds] = useState([])
    const { user = null } = useSelector(state => state.auth) || { user: null }
    
    const { cartItems, unknownCheckoutUser = {} } = useSelector(state => state?.cart) || { cartItems: [], unknownCheckoutUser: {} }
    const [secrets, setClientSecrets] = useState({
        clientSecret: null,
        donationIds: [],
    });
    const [checkClientSercret, { loading }] = useMutation(GET_CLIENT_SECRET)

    const [checkUnknownClientSercret, { loading: loadUnknownCHeckout }] = useMutation(GET_UNKNOWN_CHECKOUT)
    const options = {
        theme: 'stripe', 
    };

    const getClientSercret = async () => {
        try {
            const { data } = await checkClientSercret({
                variables: {
                    isAnonymous: false,
                    paymentMethodId: null
                }
            })
            const { checkout } = data || { checkout: {} }
            const { clientSecret = null, donationIds = [] } = checkout || { clientSecret: null, donationIds: [] }

            setClientSecrets({
                ...secrets,
                clientSecret,
                donationIds
            })
            setDonationIds(donationIds)
            updateProjectOverviews()
        } catch (error) {
            setClientSecrets({
                ...secrets,
                clientSecret: "",
                donationIds: []
            })
            setDonationIds([])
            updatePaymentStatus({
                ...isPaymentCompleted,
                status: false,
                error: true,
                errorMessage: error.message
            })
            showToast({ message: error.message, variant: 'error' })
        }
    }

    const getUnknownSecret = async () => {
        try {
            const { data } = await checkUnknownClientSercret({
                variables: {
                    basketItems: (cartItems?.length && cartItems?.map(cart => ({
                        projectId: Number(cart?.id),
                        amount: parseFloat(cart?.amount),
                        type: "CUSTOM_AMOUNT",
                        quantity: 1,
                    }))) || [],
                    currency: localStorage.getItem("currency") || "USD",
                    paymentGateway: "stripe",
                    ...unknownCheckoutUser ,
                    ...user?.email && {
                        email: user?.email || '',
                        firstName: user?.firstName,
                        lastName: user?.lastName,
                    } 
                }
            })
            const { checkoutUnknown } = data || { checkoutUnknown: {} }
            const { clientSecret = "", donationIds = [] } = checkoutUnknown || { clientSecret: "", donationIds: [] }
            setClientSecrets({
                ...secrets,
                clientSecret,
                donationIds
            })
            setDonationIds(donationIds)
            updateProjectOverviews({})
        } catch (error) {
          
            updatePaymentStatus({
                ...isPaymentCompleted,
                status: false,
                error: true,
                errorMessage: error.message
            })
            showToast({ message: error.message, variant: 'error' })
        }
    }

    useEffect(() => {
        if (cartItems?.length) {
            if (!user || isAnonymous) {
                getUnknownSecret();
             } else {
                getClientSercret()
            }
        }
        //eslint-disable-next-line 
    }, [user, cartItems, isAnonymous])

    return (loading || loadUnknownCHeckout || !secrets?.clientSecret) ? <Loader /> : <Elements stripe={stripePromise} options={{ ...options, clientSecret: secrets?.clientSecret }}><CheckOutForm isPaymentCompleted={isPaymentCompleted} donationIds={donationIds} updatePaymentStatus={updatePaymentStatus} /></Elements >
};

export default CreditCardForm;

const Loader = () => {
    return (<div className='h-fit w-fit m-auto min-h-[20rem] flex flex-col items-center'>
        <SpinnerPrimaryIcon />
    </div>)
}

const CheckOutForm = ({ updatePaymentStatus, isPaymentCompleted, donationIds }) => {
    const stripe = useStripe();
    
    const elements = useElements();
    const showToast = useShowToast();
    const [stripeLoader, setStripeLoader] = useState(false)

    const handleSubmit = async (event) => {
        event.preventDefault();
        setStripeLoader(true);
        if (!stripe || !elements) {
            return;
        }

         const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: window.location.href,
            },
            redirect: 'if_required',
        });

        if (result?.error) {
            showToast({ message: result?.error?.message, variant: 'error' });
        } else {
            const statuses = {
                succeeded: { message: "Thank You!. We have successfully received your payment", variant: "success" },
                processing: { message: "Your payment is processing.", variant: "info" },
            }
            showToast({ ...statuses[result?.paymentIntent?.status] });
            setStripeLoader(false);

            if (result?.paymentIntent?.status === "succeeded") { 
                updatePaymentStatus({
                    ...isPaymentCompleted,
                    status: true,
                    donationIds: donationIds?.map(e => Number(e)),
                    error: false,
                    errorMessage: ''
                })
            }
        }


    };

    return (

        <div className='flex flex-col gap-5 p-5 border rounded-lg border-neutral-400'>
            <div className='pb-5 border-b border-neutral-400'>
                <Typography variant="mediumText" className="font-bold text-center text-neutral-800" text="Credit/Debit Card" />
            </div>
            <form onSubmit={handleSubmit}  >
                <PaymentElement />
                <Button
                    variant="primary"
                    disabled={!stripe || stripeLoader || isPaymentCompleted?.error}
                    label={"Proceed"}
                    loader={stripeLoader}
                    loaderText="Processing..."
                    iconClassName="hidden"
                    className="w-full my-4"
                />
            </form>
        </div>

    );
}

import React, { memo, useEffect } from 'react';
import propTypes from 'prop-types';
import { Tabs } from 'flowbite-react';
import { tabTheme } from '../../Utils/flowbite-theme';
import { useSearchParams } from 'react-router-dom';

export const CustomTabs = memo(({ tabContent, tabChanged = () => null, className }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  let params = { ...Object.fromEntries([...searchParams]) }

  const activeTab = searchParams?.get('tab') || "";

  const handleChangeTab = (index) => {
    const newList = [...tabContent]
    const { tab } = newList?.at(index) || "";
    setSearchParams({ ...params, tab: tab });
    tabChanged(tab)
  }

  useEffect(() => {
    if (!activeTab && tabContent?.length) {
      const { tab } = tabContent?.at(0) || ""
      setSearchParams({ ...params, tab: tab })
    }
    //eslint-disable-next-line
  }, [activeTab, tabContent, params])

  return (
    <Tabs variant="default" theme={tabTheme} onActiveTabChange={handleChangeTab} className={`${className}`}>
      {!!tabContent?.length
        && tabContent?.map((tab, index) => (
          <Tabs.Item
            key={tab}
            title={tab.title}
            disabled={tab.disabled}
            active={  
              String(activeTab) === String(tab?.tab)
              }>
            {tab?.children}
          </Tabs.Item>
        ))}
    </Tabs>
  );
});

CustomTabs.propTypes = {
  tabContent: propTypes.array,
  className: propTypes.string
}
import React, { useEffect, useRef } from 'react'
import Lottie from 'lottie-web';
import animationData from './loader-animation.json';

const LoaderAnimation = ({ width = 100, height = 100 }) => {

    const containerRef = useRef(null);

    useEffect(() => {
        Lottie.loadAnimation({
            container: containerRef.current,
            animationData: animationData,
            renderer: 'svg',
            loop: true,
            autoplay: true,
        });
    }, []);

    return (
        <div className='flex items-center justify-center place-content-center m-auto h-dvh'>
            <div ref={containerRef} style={{ width: width, height: height }}></div>
        </div>
    );
};

export default LoaderAnimation

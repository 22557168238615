import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import customTheme from '../../Utils/flowbite-theme';

export const SelectComponent = memo(({ label, initialValue, onChange, className, customTheme, selectOptions, placeHolder, labelClassName, disabled }) => {
   
    const handleChange = (selectedOption) => {
        onChange(selectedOption.value);
    };

    const defaultValue = React.useCallback(() => selectOptions?.find(option => String(option?.value) === String(initialValue)),[selectOptions, initialValue]);

 
    return (
        <div>
            {label && <label className={`block mb-2 font-normal text-sm/4 text-neutral-1000 ${labelClassName}`}>{label}</label>}
            <Select
                options={selectOptions}
                value={defaultValue()}
                disabled={disabled}
                onChange={handleChange}
                theme={customTheme}
                className={className}
                styles={{
                    control: (provided, state) => ({
                        ...provided,
                        borderRadius: '8px',
                        cursor: 'pointer',
                        borderColor: state.isFocused ? '#2980F5' : '#C0BEB9',
                        borderWidth: state.isFocused ? '1px' : provided.borderWidth,
                        outline: state.isFocused ? '' : 'none',
                        '&:hover': {
                            borderColor: '#2980F5',
                        },
                        '&:focus': {
                          borderWidth: '1px',
                          outline: 'none'  
                        },
                        padding: '0px 4px',
                        height: '44px',
                    }),
                    placeholder: provided => ({
                        ...provided,
                        color: '#AAA7A0',
                        fontSize: '14px',
                        lineHeight: '20px',
                    }),
                    option: provided => ({
                        ...provided,
                        fontSize: '14px', 
                        lineHeight: '16px',
                        fontWeight: '400',
                        color: '#2B241F',
                        textTransform: 'capitalize'
                    }),
                }}
                placeholder={placeHolder}
            />
        </div>
    );
});

SelectComponent.propTypes = {
    initialValue: PropTypes.string,
    selectOptions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    onChange: PropTypes.func,
    className: PropTypes.string,
    customTheme: PropTypes.object,
    label: PropTypes.string,
    placeHolder:PropTypes.string,
    labelClassName: PropTypes.string,
};

SelectComponent.defaultProps = {
    initialValue: '',
    selectOptions: [],
    onChange: () => { },
    className: " ",
    label: "",
    placeHolder: "",
    labelClassName: "",
    customTheme: customTheme
};

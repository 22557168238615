import { Navigate, Link } from 'react-router-dom'


const ProtectedNavigate = ({to}) => {
	if(process.env.REACT_APP_APPLY_PROTECTION_REDIRECTS==="true")
		return <div className="">Go to <Link to={to}>{to}</Link> (in dev only)</div>
	else
		return <Navigate to={to} />
}

export default ProtectedNavigate

import { gql } from '@apollo/client'

const GET_PROFILE = gql`
query GetProfile {
  getProfile {
    id
    firstName
    lastName
    displayName
    email
    role
    status
    profileImage
  }
}`

export default GET_PROFILE

import React from "react";
import Routing from "./Routes";
import ApolloAppProvider from "./Utils/ApolloProvider";
import { ToastProvider } from './Utils/toast'

const App = () => {
    return ( 
        <ApolloAppProvider data-testid="apollo-provider">
            <ToastProvider>
                <Routing data-testid="routing" />
            </ToastProvider>
        </ApolloAppProvider>
    );
};

export default App;






import { createSlice } from "@reduxjs/toolkit";

const carts = JSON.parse(localStorage.getItem("cart") || "[]"); 

const initialState = {
  cartItems:  carts ,
  unknownCheckoutUser: {},
};

const counterSlice = createSlice({
  name: "cart",
  initialState: initialState,
  reducers: {
    updateCartItems: (state, action) => ({
      ...state,
      cartItems: action?.payload || []
    }),
    updateUnknownCheckoutUser: (state, action) => ({
      ...state,
      unknownCheckoutUser: { ...state.unknownCheckoutUser, ...action?.payload },
    }),
  },
});

export const { updateCartItems, updateUnknownCheckoutUser } =
  counterSlice.actions;

export default counterSlice.reducer;

import { gql } from '@apollo/client';

export const GET_UPLOAD_LINK = gql`
    query GetUploadLink($files: [File]!) {
        getUploadLink(files: $files) {
            fileFields {
                url
                fields
                key
            }
        }
    }
`

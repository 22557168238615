import { createSlice } from "@reduxjs/toolkit";
 
const initialState = {
  user: null  ,
  token: null,
  initializing: true,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoggedInUser(state, action) { 
      state.socialMediaLogin=  action.payload.socialMediaLogin;
      state.user = action.payload.profile;
      state.initializing = false;
      state.token = action.payload.token;
    },
    logoutUser(state) {
      state.user = null;
      state.token = null;
      state.initializing = false;
    },
    clearUserState(state) {
      state.user = null;
      state.token = null;
      state.initializing = false;
    },
    updateUser(state, action) {
      state.user = { ...state.user, ...action.payload };
    },
  },
});

export const { setLoggedInUser, logoutUser, clearUserState, updateUser } =
  authSlice.actions;
export default authSlice.reducer;

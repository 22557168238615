import React, { memo } from 'react';
import PropTypes from 'prop-types';

const typographyVariants = {
  heading: {
    classes: 'text-2xl font-bold'
  },
  heading1: {
    classes: 'text-heading-1'
  },
  heading2: {
    classes: 'text-heading-2'
  },
  heading3: {
    classes: 'text-heading-3'
  },
  heading4: {
    classes: 'text-heading-4'
  },
  heading5: {
    classes: 'text-heading-5'
  },
  heading6: {
    classes: 'text-heading-6'
  },
  heading7: {
    classes: 'text-heading-7'
  },
  largeText: {
    classes: 'text-large-text'
  },
  mediumText: {
    classes: 'text-medium-text'
  },
  smallText: {
    classes: 'text-small-text'
  },
  captionText: {
    classes: 'text-caption-text'
  },
  authText: {
    classes: 'font-normal tracking-tight text-sm/4'
  }
};

export const Typography = memo(({ variant, text, textHtml, className}) => {
  const variantStyles = typographyVariants[variant];
  const combinedClassName = `${variantStyles.classes} ${className}`;

  if(textHtml)
    return <p className={combinedClassName} dangerouslySetInnerHTML={{__html: textHtml}} />;
  return <p className={combinedClassName}>{text}</p>;
});

Typography.propTypes = {
  variant: PropTypes.oneOf(['heading','heading1','heading2','heading3','heading4','heading5','heading6', 'heading7','largeText','mediumText','smallText','captionText','authText']),
  text: PropTypes.node,
  textHtml: PropTypes.node,
  className: PropTypes.string,
};

Typography.defaultProps = {
  variant: 'heading',
  text: '',
  className: '', 
  textHtml: ''
};

import { object, number } from "yup";

export const amountValidationSchema = object({
  amount: number()
    .positive()
    .required("Amount is required")
    .min(0.0, "Amount is required")
    .max(10000000.0, "Maximum amount is 10,000000")
    .test("decimalLength", "Invalid decimal length", (value) => {
      const decimalPart = value.toString().split(".")[1];
      return decimalPart && value ? decimalPart.length <= 2 : true; // adjust the length as needed
    })
    .typeError("Amount is invalid"),
});

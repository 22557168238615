const customTheme = {
  button: {
    base: "group flex items-stretch items-center justify-center p-0.5 text-center font-medium relative focus:z-10 focus:outline-none transition-[color,background-color,border-color,text-decoration-color,fill,stroke,box-shadow]",
    color: {
      primary: "bg-red-500 hover:bg-red-600",
    },
  },
  input: {
    base: "border border-gray-300 rounded-md shadow-sm focus:ring-cyan-500 focus:border-cyan-500",
    disabled: "opacity-50 cursor-not-allowed",
    error: "border-red-500",
  },
  checkbox: {
    base: "rounded border-gray-300 text-cyan-600 focus:ring-cyan-500",
    checked: "bg-cyan-600 border-transparent",
    disabled: "opacity-50 cursor-not-allowed",
  },
  radio: {
    base: "rounded-full border-gray-300 text-cyan-600 focus:ring-cyan-500",
    checked: "bg-cyan-600 border-transparent",
    disabled: "opacity-50 cursor-not-allowed",
  },
  size: {
    xs: "text-xs px-2 py-1",
    sm: "text-sm px-3 py-1.5",
    md: "text-sm px-4 py-2",
    lg: "text-base px-5 py-2.5",
    xl: "text-base px-6 py-3",
  },
};

export default customTheme;

export const tabTheme = {
  base: "flex flex-col gap-2 test-class",
  tablist: {
    base: "flex text-center",
    variant: {
      default: "flex-wrap border-b  !border-neutral-400 !border-neutral-400 ",
    },
    tabitem: {
      base: "py-2 max-lg:w-1/2 px-7 flex items-center justify-center  text-meduim font-bold  focus:outline-none focus:ring-0 focus:ring-primary-300 disabled:cursor-not-allowed disabled:text-gray-400 ",
      variant: {
        default: {
          base: "rounded-t-lg",
          active: {
            on: "text-primary-300 border-b-2 border-primary-300",
            off: "text-neutral-800  ",
          },
        },
      },
      icon: "mr-2 h-5 w-5",
    },
  },
  tabitemcontainer: {
    base: "",
    variant: {
      default: "",
      underline: "",
      pills: "",
      fullWidth: "",
    },
  },
  tabpanel: "py-3",
};

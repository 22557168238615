import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export const Dropdown = ({ label, menuItems, chevronDownIcon, className, imgSrc, hoverClassName, loading=false }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`relative flex flex-col items-center justify-center px-1 py-1 rounded-lg gap-x-2  ${hoverClassName}`}>
      <button
        id="dropdownDividerButton"
        disabled={loading}
        data-dropdown-toggle="dropdownDivider"
        className="inline-flex items-center gap-2 font-bold text-center text-neutral-900 text-sm/4"
        type="button"
        onClick={toggleDropdown}
      >
        <img src={imgSrc} alt="Profile" className='object-fill rounded-full size-8' onError={
          (e) => e.target.src = "/assets/images/no-image-available.svg"
        } />
        <span className='hidden md:inline-flex'>{label}</span>
        <span className="hidden md:inline-flex text-neutral-700">
          {chevronDownIcon}
        </span>
      </button>

      {isOpen && (
        <div
          id="dropdownDivider"
          className={`absolute top-0 -right-4 lg:right-0 mt-14 sm:mt-12 z-20 px-4  bg-white  rounded-lg shadow w-screen sm:w-44 ${className}`}
        >
          <ul className="py-2 text-sm" aria-labelledby="dropdownDividerButton">
            {menuItems.length > 0 && menuItems.map(item => (
              <li key={item?.text} className='group last:pt-3'>
                {item?.type === "button" ? <div role="button" onClick={(e) => { item?.click(e, item); setIsOpen(!isOpen); }}
                  className="flex items-center gap-3 py-2 group-last:border-t group-last:border-neutral-400 group-last:pt-4">
                  <span>{item.icon}</span>
                  {item.text}
                </div> :
                  <Link
                    to={item?.href}
                    onClick={item.onClick}
                    className="flex items-center gap-3 py-2 group-last:border-t group-last:border-neutral-400 group-last:pt-4"
                  >
                    <span>{item.icon}</span>
                    {item.text}
                  </Link>}

              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  chevronDownIcon: PropTypes.string,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string,
      type: PropTypes.string,
      onClick: PropTypes.func
    })
  ),
  imgSrc: PropTypes.string,
  hoverClassName: PropTypes.string
};


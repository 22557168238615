import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpIcon, XIcon } from '../../Theme/icons';

export const SideBar = memo(({ items, className, projectTitle }) => {
  const [closeSideBar, setCloseSideBar] = useState(true);

  const handleCloseSidebar = () => {
    setCloseSideBar(false);
  };

  const isSmallScreen = () => window.matchMedia('(max-width: 767px)').matches;

  const handleItemClick = () => {
    if (isSmallScreen()) {
      handleCloseSidebar();
    }
  };

  return (
    <div className={`max-md:inset-0 max-md:h-screen max-md:z-50 max-md:absolute max-md:bg-gray-900/40 bg-opacity-40 ${closeSideBar ? '' : 'hidden'}`}>
      <div className={`bg-white p-5 flex flex-col gap-4 text-neutral-700 text-sm/4 font-bold tracking-tight h-dvh lg:h-dvh-minus-20 max-w-67.5 border-e border-solid border-neutral-300 ${className}`}>
        <div className="hidden max-md:flex max-md:items-center max-md:justify-between max-md:mb-4">
          <img src='/assets/images/logo-sidebar.svg' alt='Build The Masjid'/>
          <div className="text-neutral-1000">
            <XIcon onClick={handleCloseSidebar} tabIndex="0" />
          </div>
        </div>
        {projectTitle && (
          <div className="rounded-xl bg-neutral-200 p-2.5 text-neutral-900 text-heading-7 text-pretty">
            <h1>{projectTitle}</h1>
          </div>
        )}
        <ul className="flex flex-col gap-4 overflow-y-auto">
          {items.length > 0 && items.map((item) => (
            <React.Fragment key={item.id}>
              <li
                className={`w-full flex items-center px-2 py-1 rounded-lg hover:text-primary-300 ${item.subItems ? '' : 'active:text-primary-300 active:bg-primary-100 focus:bg-primary-100 focus-within:bg-primary-100 focus:text-primary-300 focus-visible:outline-none target:bg-primary-100'} ${item.active ? '!text-primary-300 !bg-primary-100' : ''}`}
              >
                <Link to={item.url} className="flex items-center w-full p-3" onClick={handleItemClick}>
                  <span className='flex items-center gap-x-2.5 me-auto'>
                    {item.icon}
                    {item.label}
                  </span>
                  {item.subItems && (
                    <>
                      {item.active ? (
                        <ChevronUpIcon />
                      ) : (
                        <ChevronDownIcon size="15" />
                      )}
                    </>
                  )}
                </Link>
                {item.chip}
                {item.active && item.subItems && (
                  <ul className="pl-4">
                    {item.subItems.map((subItem, subIndex) => (
                      <li
                        key={subIndex}
                        className="px-4 py-2 cursor-pointer hover:bg-gray-700"
                      >
                        <Link to={subItem.url} className="flex items-center w-full px-5 py-4 rounded-lg hover:text-primary-300 active:text-primary-300 active:bg-primary-100 focus:bg-primary-100 focus-within:bg-primary-100 focus-visible:outline-none target:bg-primary-100" onClick={handleItemClick}>
                          {subItem.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            </React.Fragment>
          ))}
        </ul>
      </div>
    </div>
  );
});

SideBar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      subItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
          label: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        })
      ),
      icon: PropTypes.node,
      chip: PropTypes.node,
    })
  ),
  className: PropTypes.string,
  projectTitle: PropTypes.string,
};

SideBar.defaultProps = {
  items: [],
  className: "",
  projectTitle: "",
};

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import customTheme from '../../Utils/flowbite-theme';

export const Checkbox = memo(({ checked, onChange, label, className, customTheme, disabled}) => {
  const handleCheckboxChange = () => {
    onChange(!checked); 
  };
  return (
    <label className={`inline-flex items-center ${className}`}>
      <input
        type="checkbox"
        className="check !text-primary-300 h-5 w-5 cursor-pointer"
        checked={checked}
        onChange={handleCheckboxChange} 
        style={customTheme}
        disabled={disabled}
      />
      <span className="ml-2">{label}</span>
    </label>
  );
});

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string,
  customTheme:PropTypes.object,
  disabled: PropTypes.string
};

Checkbox.defaultProps = {
  checked: false,
  onChange: () => {},
  label: 'Checkbox',
  className: '', 
  customTheme:customTheme,
  disabled: false
};

import { memo } from 'react';
import PropTypes from 'prop-types';
import { Table as FlowbiteTable } from 'flowbite-react';

export const Table = memo(({ data, columns, className }) => {
  return (
    <div className={`overflow-x-auto ${className}`}>
      <FlowbiteTable>
        <FlowbiteTable.Head>
          {columns?.map((column) => (
            <FlowbiteTable.HeadCell key={`column-${column}`}>{column}</FlowbiteTable.HeadCell>
          ))}
        </FlowbiteTable.Head>
        <FlowbiteTable.Body className="divide-y">
          {data?.map((row) => (
            <FlowbiteTable.Row key={`row-${row.join("-")}`} className="bg-white dark:border-gray-700 dark:bg-gray-800">
              {row?.map((cell) => (         
                <FlowbiteTable.Cell
                  key={`cell-${row}-${cell}`} 
                  className="font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {cell}
                </FlowbiteTable.Cell>
              ))}
            </FlowbiteTable.Row>
          ))}
        </FlowbiteTable.Body>
      </FlowbiteTable>
    </div>
  );
});

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.node)),
  columns: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
};

Table.defaultProps = {
  data: [],
  columns: [],
  className: "",
};

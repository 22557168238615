import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

export const MultiSelect = memo(({ options, onChange, className, selectedOptions }) => {
  const [selectedValues, setSelectedValues] = useState(selectedOptions);

  const handleChange = (selectedOptions) => {
    setSelectedValues(selectedOptions);
    onChange(selectedOptions);
  };

  return (
    <Select
      options={options}
      isMulti
      onChange={handleChange}
      className={`basic-multi-select ${className}`}
      classNamePrefix="select"
      value={selectedValues}
    />
  );
});

MultiSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  onChange: PropTypes.func,
  className: PropTypes.string,
  selectedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  )
};

MultiSelect.defaultProps = {
  options: [],
  onChange: () => {},
  selectedOptions: [],
};

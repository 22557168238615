import React, { useState, useRef, useEffect } from 'react';

const TipSelector = () => {
    const [tipPercentage, setTipPercentage] = useState(0);
    const [tooltipOffset, setTooltipOffset] = useState(0);
    const tooltipRef = useRef(null);
    const rangeRef = useRef(null);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setTipPercentage(value);
        updateTooltipPosition(value);
    };

    const updateTooltipPosition = (value) => {
        const rangeWidth = rangeRef.current.offsetWidth;
        const thumbWidth = 24; // Assuming thumb is 24px wide
        const thumbOffset = (value / 100) * (rangeWidth - thumbWidth) + thumbWidth / 2;
        const tooltipWidth = tooltipRef.current.offsetWidth;
        const newTooltipOffset = thumbOffset - tooltipWidth / 2 + 80;

        setTooltipOffset(newTooltipOffset);
    };

    useEffect(() => {
        // Set initial tooltip position
        if (rangeRef.current && tooltipRef.current) {
            updateTooltipPosition(tipPercentage);
        }
    }, [tipPercentage]);

    
    return (
        <div className="flex flex-col items-center">
            <label htmlFor="tipRange" className="sr-only">
                Tip Percentage
            </label>
            <div className="relative w-full">
                <div className="flex items-center gap-2 font-bold text-sm/4 text-neutral-1000">
                    <span>0%</span>
                    <input
                        type="range"
                        id="tipRange"
                        min="0"
                        max="100"
                        step="1"
                        value={tipPercentage}
                        onChange={handleInputChange}
                        className="w-full h-2 rounded-lg appearance-none bg-neutral-300"
                        ref={rangeRef}
                        style={{
                            background: `linear-gradient(to right, #2980F5 0%, #2980F5 ${tipPercentage}%, #F4F1EA ${tipPercentage}%, #F4F1EA 100%)`,
                            outline: 'none',
                        }}
                    />
                    <span>100%</span>
                </div>

                <div
                    ref={tooltipRef}
                    className="absolute left-0 p-2 text-xs transition-opacity duration-200 ease-in-out transform -translate-x-1/2 bg-white rounded shadow text-neutral-1000 -top-14"
                    style={{
                        left: `${tooltipOffset}px`,
                        //opacity: tipPercentage ? 1 : 0,
                        whiteSpace: 'nowrap',
                    }}
                >
                    <div className="flex items-center gap-1 text-sm/4">
                        <div className="font-bold text-neutral-1000">$5.00</div> {/* Replace this with dynamic value if needed */}
                        <div className="text-neutral-700">{`(${tipPercentage}%)`}</div>
                    </div>
                    <div className="tooltip-arrow" />
                </div>
            </div>
        </div>
    );
};

export default TipSelector;

import { createSlice } from '@reduxjs/toolkit';
import { clearUserState } from '../Redux/reducer'; 

const initialState = {
    projectId: null,
    loading: false,
    one: {
        address: '',
        location: null,
        country: '',
        zip: '',
        city: '',
    },
    two: {
        name: '',
        slug: '',
        description: '',
        launchDate: null,
    },
    three: {
        images: [],
        video: '',
    },
    four: {
        goalAmount: '',
        goalAmountCurrency: '',
    },
    five: {
        rewards: [],
    },
    page: 1,
};

const createProjectSlice = createSlice({
    name: 'createProject',
    initialState,
    reducers: {
        setCreateProjectLoading(state, action) {
            state.loading = action.payload;
        },
        setCreateProjectId(state, action) {
            state.projectId = action.payload;
        },
        setCreateProjectPageData(state, action) {
            state[action.payload.page] = action.payload.data;
        },
        setCreateProjectPage(state, action) {
            state.page = action.payload;
        },
        resetCreateProject(state) {
            return {
                ...state,
                page: 1,
                loading: false,
                projectId: null,
                one: {
                    address: '',
                    location: null,
                    country: '',
                    zip: '',
                    city: '',
                },
                two: {
                    name: '',
                    slug: '',
                    description: '',
                    launchDate: null,
                },
                three: {
                    images: [],
                    video: '',
                },
                four: {
                    goalAmount: '',
                    currency: '',
                },
                five: {
                    rewards: [],
                },
            };
        },
        updateImages(state,action){
             return {...state,three: { ...state.three, images: action.payload} }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(clearUserState, (state) => {
            return {
                ...state,
                page: 1,
                loading: false,
                projectId: null,
                one: {
                    address: '',
                    location: null,
                    country: '',
                    zip: '',
                    city: '',
                },
                two: {
                    name: '',
                    slug: '',
                    description: '',
                    launchDate: null,
                },
                three: {
                    images: [],
                    video: '',
                },
                four: {
                    goalAmount: '',
                    currency: '',
                },
                five: {
                    rewards: [],
                },
            };
        });
    },
});

export const {
    setCreateProjectLoading,
    setCreateProjectId,
    setCreateProjectPageData,
    setCreateProjectPage,
    resetCreateProject,
    updateImages
} = createProjectSlice.actions;
export default createProjectSlice.reducer;

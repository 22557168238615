import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import customTheme from '../../Utils/flowbite-theme';


export const Radio = memo(({ options, defaultChecked,className,customTheme }) => {
  const [selectedOption, setSelectedOption] = useState(defaultChecked);

  const handleRadioChange = (name) => {
    setSelectedOption(name);
  };

  return (
    <div className={`flex w-max gap-4 ${className}`}>
      {options && options.length > 0
        ? options.map((option) => (
            <div key={option.name} className="flex items-center">
              <input
                type="radio"
                name="gender"
                id={option.name}
                checked={selectedOption === option.name}
                onChange={() => handleRadioChange(option.name)}
                className="form-radio h-5 w-5 text-indigo-600"
              />
              <label htmlFor={option.name} className="ml-2 text-gray-700" style={ customTheme }
>
                {option.name}
              </label>
            </div>
          ))
        : null}
    </div>
  );
});

Radio.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      color: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ),
  defaultChecked: PropTypes.string,
  className: PropTypes.string,
  customTheme:PropTypes.object
};

Radio.defaultProps = {
  options: [
    { name: "male", color: "blue", disabled: false },
    { name: "female", color: "green", disabled: false },
  ],
  defaultChecked: "",
  className: " ",
  customTheme:customTheme
};


import { useSelector } from 'react-redux'
import ProtectedNavigate from '../Components/ProtectedNavigate';
import PropTypes from 'prop-types';


const ProtectedRoute = ({children, redirectNoAuth, redirectUser, redirectAdmin}) => {
    const profile = useSelector(state => state.auth.user);

	 

    if(!profile && redirectNoAuth)
        return <ProtectedNavigate to={redirectNoAuth} />

    if(profile?.role==="USER" && redirectUser)
        return <ProtectedNavigate to={redirectUser} />

    if((profile?.role==="ADMIN" || profile?.role===" ") && redirectAdmin)
        return <ProtectedNavigate to={redirectAdmin} />

    return children;
}

ProtectedRoute.propTypes = {
    children: PropTypes.node,
    redirectNoAuth: PropTypes.string,
    redirectUser: PropTypes.string,
    redirectAdmin: PropTypes.string,
};
export default ProtectedRoute

import React, { useEffect, useState } from 'react'
import ProjectDetailsPageHeader from '../../../../Layouts/Header/ProjectDetailsPage'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ArrowLeftIcon } from '../../../../Theme/icons'
import { Button, Typography } from '../../../../Components'
import { useQuery } from '@apollo/client'
import { GET_PAYPAL_STATUS } from '../../../../GraphQL/Queries/checkout'
import { useDispatch } from 'react-redux'
import { updateCartItems } from '../../../../Redux/cartReducer'


const messages = {
    PENDING: {
        title: "Please wait",
        subTitle: "We are collecting your transaction information from paypal. Please wait...",
    },
    SUCCESS: {
        title: "Thank you for your donation",
        subTitle: " A receipt and certificate has been sent to your registered email id"
    },
    FAILED: {
        title: "Oops!. Something went wrong",
        subTitle: "We are unable to check your payment status due to an error. Please try again later"
    },

}

const PayPalCompleted = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams?.get('token');
    const [messge, setMessgae] = useState({})

    const { data: { getPaypalPaymentsByToken = [], error } = {}, loading } = useQuery(GET_PAYPAL_STATUS, {
        variables: {
            token: token
        },
        skip: !token
    });


    const handleDownloadCertificateAgain = () => {
        if (getPaypalPaymentsByToken?.length) {
            const { certificateUrl, paymentStatus } = getPaypalPaymentsByToken?.at(0) || ""
            paymentStatus === "APPROVED" && setMessgae(messages?.SUCCESS);

            certificateUrl && fetch(certificateUrl)
                .then(response => response.blob())
                .then(blob => {
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = `certificate-of-donations.pdf`;
                    link.click();
                });
        }
    }


    useEffect(() => {
        if (loading) {
            setMessgae(messages?.PENDING)
        }
        if (error) {
            setMessgae(messages?.FAILED)
        }
        if (getPaypalPaymentsByToken?.length) {
            const {  paymentStatus } = getPaypalPaymentsByToken?.at(0) || ""
            paymentStatus === "APPROVED" && setMessgae(messages?.SUCCESS);
             localStorage.removeItem('cart')
            dispatch(updateCartItems([]))

        }
    }, [loading, error, getPaypalPaymentsByToken])

    return (
        <div className='relative'>
            <header>
                <ProjectDetailsPageHeader />
                <div className='bg-primary-100 px-4 md:px-7.5 py-3.5 md:py-6'>
                    <Button
                        type="button"
                        loader={false}
                        loaderText=""
                        label="Back"
                        iconClassName=""
                        loaderClassName=""
                        icon={<ArrowLeftIcon />}
                        className="!p-0 me-auto"
                        variant='primary-text'
                        onClick={() => navigate(-1)}
                    />
                </div>
            </header>

            <div className="flex flex-col items-center gap-5  rounded-md max-w-lg m-auto h-[70vh]  justify-center">
                <div className='space-y-2.5 w-full'>
                    <img className='mx-auto' src="/assets/images/donation.svg" alt="Donation" />
                    <div className="space-y-4 text-center">
                        <Typography variant="heading5" className="text-neutral-1000 max-md:text-heading-6" text={messge?.title} />
                        <p className='mx-auto text-medium-text text-neutral-1000 w-60'> {messge?.subTitle} </p>

                    </div>
                </div>
                {!loading && <Button
                    variant="primary-outline"
                    label="Download Certificate"
                    loader={loading}
                    disabled={loading}
                    loaderText="Generating Certificate..."
                    onClick={handleDownloadCertificateAgain}
                    iconClassName="hidden"
                    className="w-fit"
                />}

            </div>
        </div>


    )
}

export default PayPalCompleted

import React, { memo } from 'react';
import PropTypes from 'prop-types';

const CurrencySelector = memo(({ currencies, selectedCurrency, currencyDisabled, amount, className, onChange, onAmountChange, placeHolder }) => {
  const handleCurrencyChange = (event) => {
    const newCurrency = event.target.value;
    onChange(newCurrency);
  };

  const handleAmountChange = (event) => {
    const newAmount = event.target.value;
    onAmountChange(newAmount);
  };
 

  return (
    <div className={`flex items-center input !py-0 ${className}`}>
      <label className="sr-only" htmlFor='currency'>Select Currency</label>
      <select
        id='currency'
        value={selectedCurrency}
        disabled={currencyDisabled}
        onChange={handleCurrencyChange}
        className="border-0 rounded-none outline-none focus-visible:outline-none shadow-none me-2.5 !text-sm/4 text-neutral-1000 cursor-pointer bg-transparent"
      >
        {currencies.map((currency) => (
          <option key={currency} value={currency}>
            {currency}
          </option>
        ))}
      </select>
      <label className="sr-only" htmlFor='amount'>Goal amount</label>
      <input
        value={amount}
        id="amount"
        // type="number"
        className="w-full border-0 rounded-none outline-none border-neutral-400 border-s-2 ps-2.5 placeholder-shown:text-sm/7 placeholder-shown:text-neutral-600 placeholder-shown:font-normal text-primary-300 text-heading-5  flex items-start placeholder:me-2"
        placeholder={placeHolder}
        onChange={handleAmountChange}
      />
    </div>
  );
});

CurrencySelector.propTypes = {
  currencies: PropTypes.arrayOf(PropTypes.string),
  selectedCurrency: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  onAmountChange: PropTypes.func,
  amount: PropTypes.func,
  placeHolder: PropTypes.string,

}

CurrencySelector.defaultProps = {
  currencies: [],
  selectedCurrency: '',
  onChange: () => { },
  onAmountChange: () => { },
  amount: () => { },
  placeHolder: "Enter Goal"
};

export default CurrencySelector;


import { memo, React } from 'react';
import PropTypes from 'prop-types';
import customTheme from '../../Utils/flowbite-theme';
import { Spinner } from '../../Theme/icons';

export const Button = memo(({ loaderClassName, iconPos, icon, iconClassName, variant, loader, loaderText, outline, children, onClick, className, label, labelClassName, customTheme, onChange, ...rest }) => {
  const variants = {
    "primary": "bg-primary-300 hover:bg-primary-400 text-neutral-100 focus:ring-2 focus:ring-primary-200 active:bg-primary-300 active:ring-0 disabled:bg-neutral-500 disabled:text-white",
    "primary-outline": "border-2 border-primary-300 hover:bg-primary-100 active:bg-white text-primary-300 disabled:text-neutral-500 disabled:border-neutral-500 disabled:hover:bg-transparent",
    "primary-text": "hover:bg-primary-100 text-primary-300 disabled:text-neutral-500 disabled:hover:bg-transparent",
    "secondary": "bg-secondary-300 hover:bg-secondary-400 text-neutral-100 focus:ring-2 focus:ring-secondary-200 disabled:bg-neutral-600 disabled:text-white",
    "secondary-outline": "border-2 border-secondary-300 hover:bg-secondary-100 text-secondary-300 disabled:text-neutral-500 disabled:border-neutral-500 disabled:hover:bg-transparent",
    "secondary-text": "hover:bg-primary-100 text-secondary-300 disabled:text-neutral-500 disabled:hover:bg-transparent",
    "neutral": "bg-neutral-1000 hover:bg-neutral-900 text-neutral-100 disabled:bg-neutral-600 disabled:text-white",
    "neutral-outline": "border-2 border-neutral-1000 hover:bg-neutral-300 text-neutral-1000 disabled:text-neutral-500 disabled:border-neutral-500 disabled:hover:bg-transparent",
    "neutral-text": "hover:bg-neutral-300 text-neutral-1000 disabled:text-neutral-500 disabled:hover:bg-transparent",
    "green": "bg-green-300 hover:bg-green-400 text-neutral-100 focus:ring-2 focus:ring-green-200 disabled:bg-neutral-500 disabled:text-white",
    "green-outline": "border-2 border-green-300 hover:bg-green-100 text-green-300 disabled:text-neutral-500 disabled:border-neutral-500 disabled:hover:bg-transparent",
    "green-text": "hover:bg-green-100 text-green-300 disabled:text-neutral-500 disabled:hover:bg-transparent",
    "red": "bg-red-300 hover:bg-red-400 text-neutral-100 focus:ring-2 focus:ring-red-200 disabled:bg-neutral-500 disabled:text-white",
    "red-outline": "border-2 border-red-300 hover:bg-red-100 text-red-300 disabled:text-neutral-500 disabled:border-neutral-500 disabled:hover:bg-transparent",
    "red-text": "hover:bg-red-100 text-red-300 disabled:text-neutral-500 disabled:hover:bg-transparent",
    "yellow": "bg-yellow-300 hover:bg-yellow-400 text-neutral-100 focus:ring-2 focus:ring-yellow-200 disabled:bg-neutral-500 disabled:text-white",
    "yellow-outline": "border-2 border-yellow-300 hover:bg-yellow-100 text-yellow-300 disabled:text-neutral-500 disabled:border-neutral-500 disabled:hover:bg-transparent",
    "yellow-text": "hover:bg-yellow-100 text-yellow-300 disabled:text-neutral-500 disabled:hover:bg-transparent",
    "social-button": "bg-white border border-solid border-neutral-400 text-base/5 text-neutral-700 hover:border-neutral-700",
    "social-button-primary": "bg-blue text-white hover:bg-primary-400"
  }

  const commonclassName = "flex gap-2 px-5 py-3 items-center justify-center whitespace-nowrap rounded-lg disabled:cursor-not-allowed font-semibold focus-visible:outline-none"

  return (
    <button
      onClick={(e) => {
        onClick(e); 
        onChange(e); 
      }}
      className={`${className} ${variants?.[variant]} ${commonclassName}`}
      {...rest}
    >
      {iconPos === "L" && (
        <span className={iconClassName}>{icon}</span>
      )}
      {loader ? (
        <span className={loaderClassName}><Spinner /></span>
      ) : (
        <span className={labelClassName}>{!loader && label}</span>
      )}
      {loader  && loaderText}
      {iconPos === "R" && (
        <span className={iconClassName}>{icon}</span>
      )}
    </button>
  );
});

Button.propTypes = {
  loader: PropTypes.bool,
  gradientduotone: PropTypes.string,
  loaderText: PropTypes.string,
  outline: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  onChange: PropTypes.func, // New prop
  className: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  customTheme: PropTypes.object,
  variant: PropTypes.string,
  iconPos: PropTypes.oneOf(['L', 'R']),
  icon: PropTypes.node,
  iconClassName: PropTypes.string,
  rest: PropTypes.object,
  loaderClassName: PropTypes.string,
};

Button.defaultProps = {
  loader: false,
  gradientduotone: null,
  outline: false,
  className: " ",
  label: "",
  labelClassName: " ",
  customTheme: customTheme,
  variant: "",
  loaderText: "",
  iconPos: "L",
  icon: null,
  iconClassName: "",
  rest: {},
  loaderClassName: " ",
  onChange: () => {}, 
  onClick: () => {}, 
};

export default Button;

import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../Redux/reducer";
import createProjectReducer from "./CreateProjectReducer";
import cartReducer from "./cartReducer";
 

export default configureStore({
  reducer: {
    auth: authReducer,
    createProject: createProjectReducer,
    cart: cartReducer,
  },
});

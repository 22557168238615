import { Toast as FlowbiteToast } from 'flowbite-react';
import PropTypes from 'prop-types';
import { memo, useEffect } from 'react';
import customTheme from '../../Utils/flowbite-theme';

const defaultTheme = {
  toggle: {
    base: "-m-1.5 ml-auto inline-flex h-8 w-8 rounded-lg !bg-transparent p-1.5 text-gray-400 hover:bg-white hover:white focus:ring-0 focus:ring-0",
    icon: "h-5 w-5 shrink-0",
  },
}

export const Toast = memo(({ message, variant, className, customTheme, onDismiss }) => {
  const toastClasses = {
    success: 'bg-green-300',
    error: 'bg-red-300',
    info: 'bg-primary-400',
    warning: 'bg-yellow-300',
  };
  useEffect(() => {
    const dismissTime = Number(process.env.REACT_APP_TOAST_DISMISS_TIME || 100000);
    const timer = setTimeout(() => {
      if (onDismiss) {
        onDismiss();
      }
    }, dismissTime);
    return () => clearTimeout(timer);
  }, [onDismiss]);

  return (
    <div className="fixed top-4 right-4 z-50">
      <FlowbiteToast className={`text-white ${toastClasses[variant]} ${className}`} theme={{ ...defaultTheme, ...customTheme }}>
        <div className="text-medium-text font-medium">{message}</div>
        <FlowbiteToast.Toggle onDismiss={() => onDismiss()} />
      </FlowbiteToast>
    </div>
  );
});

Toast.propTypes = {
  message: PropTypes.string,
  variant: PropTypes.oneOf(['success', 'error', 'info', 'warning']),
  className: PropTypes.string,
  customTheme: PropTypes.object,
  onDismiss: PropTypes.func,
};

Toast.defaultProps = {
  message: '',
  variant: 'success',
  className: " ",
  customTheme: customTheme,
  onDismiss: () => { },
  duration: 2000,

};



import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button } from "../Button/index";
import customTheme from '../../Utils/flowbite-theme';

const sizeClasses = {
  sm: "px-2 py-1",
  md: "px-3 py-1",
  lg: "px-4 py-2",
  xl: "px-5 py-3"
};

export const Dialogue = memo(({ isOpen, onClose, title, items, actions, onConfirm, onCancel, size, className }) => {
  if (!isOpen) return null;

  const getSizeClassName = (size) => sizeClasses[size] || sizeClasses.md;

  const dialogueStyle = {
    ...customTheme.dialogue,
   
  };

  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none ${className}`} style={dialogueStyle}>
      <div className="relative w-auto max-w-3xl mx-auto my-6">
        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
          <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
            <div className="text-3xl font-semibold">
              {title}
            </div>
            <Button
              size="md"
              onClick={onClose}
              className="px-3 py-1"
              label="×"
            />
          </div>
          <div className="relative p-6 flex-auto">
            {items}
          </div>
          <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blueGray-200">
            <Button
              size={size}
              onClick={onConfirm}
              className={`${getSizeClassName(size)} mr-4`}
              label="Confirm"
            />
            <Button
              size={size}
              onClick={onCancel}
              className={`${getSizeClassName(size)} mr-4`}
              label="Cancel"
            />
            {actions.length > 0 && actions.map(action => (
              <Button
                key={action.id} 
                size={size}
                onClick={action.onClick}
                className={`${getSizeClassName(size)} mr-4 bg-${action.color}-900`}
                label={action.label}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="fixed inset-0 bg-black opacity-25"></div>
    </div>
  );
});

Dialogue.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  items: PropTypes.node.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired
    })
  ).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  className: PropTypes.string
};

Dialogue.defaultProps = {
  size: 'md',
  className: ""
};

import React from 'react'
import { Typography } from '../Typography'
import ProcessingFee from '../ProcessingFee'
import { calculateProcessPercentageCharge } from '../../Utils/common';
import currencies from "../../Utils/country-currencies.json"


export const TotalAmount = ({ cartItems, }) => {

    const getTotalProjects = React.useCallback(() => {
        return cartItems?.length && cartItems?.reduce((totalProjects, project) => totalProjects + Number(project?.amount), 0)
    }, [cartItems])

    const calculatePrcessingFee = React.useCallback(() => {
        return calculateProcessPercentageCharge(getTotalProjects());
    }, [getTotalProjects]);

    const currency = ((currencies?.length && cartItems?.length) && currencies?.find(country => country?.currency?.code === cartItems?.at(0)?.currency)?.currency?.symbol) || cartItems?.at(0)?.currency || ''

    const totalAmount = parseFloat(calculatePrcessingFee() + getTotalProjects())

    const amountTodisplay = `${currency} ${totalAmount?.toLocaleString()}`

    return (
        <div>
            <div className='p-4 space-y-4  rounded-xl bg-primary-100'>
                <div className='flex items-start justify-between text-neutral-1000'>
                    <div className='w-1/3'>
                        <Typography variant="mediumText" text="Subtotal" />
                    </div>
                    <Typography variant="mediumText"  text={currency + ' ' + getTotalProjects()?.toLocaleString() || 0} />
                </div>
                <div className='flex items-start w-full justify-between text-neutral-1000'>
                    <div className='w-1/3'>
                        <ProcessingFee />
                    </div>
                    <Typography variant="mediumText" className="truncate" text={currency + ' ' + calculatePrcessingFee()?.toLocaleString()} />
                </div>
                <div className="border-t border-neutral-400"></div>
                <div className='flex items-start justify-between text-neutral-1000'>
                    <Typography variant="heading6" text="Total" />
                    <Typography variant="heading6" text={amountTodisplay} />
                </div>
            </div>
        </div>
    )
}

import React from 'react'
import { Button, Typography } from '../../../../Components'

const PaymentFailed = ({ toggleModal, errorMessage }) => {
    return (
        <div>
            <div className="flex flex-col items-center gap-5">
                <div className='space-y-2.5 w-full'>
                    <img className='mx-auto' src="/assets/images/donation.svg" alt="Donation" />
                    <div className="space-y-4 text-center">
                        <Typography variant="heading5" className="text-neutral-1000 max-md:text-heading-6" text="Transaction Failed!" />
                        <p className='mx-auto text-medium-text text-neutral-1000 w-60'>
                            Sorry! Your recent transaction is failed!. Please Try again later.</p>

                        <span className="flex items-center justify-center gap-1 mt-2 text-left text-red-300 text-caption-text">
                           <strong>Error Details : </strong>  {errorMessage}
                        </span>
                    </div>
                </div>
                <Button
                    variant="primary-text"
                    label="Close"
                    iconClassName="hidden"
                    className="w-full"
                    onClick={() => toggleModal()}
                />

            </div>
        </div >
    )
}

export default PaymentFailed

import React from 'react'

const ProcessingFee = () => {
    return (
        <div className="relative">
            <p className="flex items-center text-medium-text">
                Processing Fee (3%)
                <button
                    data-popover-target="popover-description"
                    type="button"
                    className="relative group"
                >
                    <svg
                        className="w-4 h-4 text-secondary-200 ms-2"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                    <span className="sr-only">Show information</span>
                    <div
                        className="absolute invisible w-56 p-2.5 text-xs transition-all duration-200 ease-in-out transform -translate-x-1/2 bg-white rounded-lg shadow-md -top-32 left-4 group-hover:visible text-neutral-1000"
                    >
                        <div className="flex items-center gap-1 text-sm/4">
                            <div className="space-y-2 font-bold text-left text-neutral-1000">
                                <p className='text-nowrap'>Processing Fee</p>
                                <p className='text-xs font-normal text-pretty text-neutral-700'>
                                    There is a processing charge of 3% included in the payment gateway charge. The transaction will include this charge as part of it. We appreciate your interest in knowing the details.    </p>
                            </div>
                        </div>
                        <div className="tooltip-arrow" />
                    </div>
                </button>
            </p>
        </div>
    )
}

export default ProcessingFee
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../Button';
import { StarIcon, TrashIcon } from '../../Theme/icons';

const AddedImage = memo(({ imgSrc, altText, isCover, onDelete, makeCover }) => {
    return (
        <div className="relative overflow-hidden group">
            <img
                src={imgSrc}
                alt={altText}
                className="object-cover w-full h-32 transition-all duration-300 transform rounded lg:w-40 group-hover:scale-105"
            />
            <div className="absolute inset-0 flex items-start justify-between p-2 transition-opacity opacity-0 group-hover:opacity-100 bg-black/50">
                <Button
                    onClick={onDelete}
                    type="button"
                    icon={<TrashIcon />}
                    labelClassName="hidden"
                    className="absolute bottom-0 right-0 !px-2 !py-1 mb-2 mr-2 bg-white !rounded text-neutral-1000 focus:outline-none"
                />
                {isCover?(
                    <div className="!gap-1 !px-2 !py-1 bg-white !rounded text-yellow-300 text-small-button-text flex">
                        <StarIcon />
                        Cover Image
                    </div>
                ):makeCover?(
                    <Button
                        onClick={makeCover}
                        type="button"
                        icon={<StarIcon />}
                        label="Make Cover"
                        className="!gap-1 !px-2 !py-1 bg-white !rounded text-neutral-800 text-small-button-text"
                    />
                ):null}
            </div>
        </div>
    );
});

AddedImage.defaultProps = {
    imgSrc: "",
    altText: "Uploaded"
};

AddedImage.propTypes = {
    imgSrc: PropTypes.string,
    altText: PropTypes.string
};

export default AddedImage;

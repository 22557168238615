import React, { useState } from 'react';
import { Checkbox, Typography } from '../../../../Components';
import TipSelector from './TipSelector';

const PaymentOption = () => {
    const options = [
        { id: 1, name: 'Vishan Fernando' },
        { id: 2, name: 'Anonymous' },
    ];

    const lists = [
        { name: 'Your Donation', value: '10.00' },
        { name: 'Build the Masjid Tip', value: '5.00' },
    ];

    const [selectedOption, setSelectedOption] = useState(null);
    const [isUpdatesChecked, setIsUpdatesChecked] = useState(false);
    const [isNewsletterChecked, setIsNewsletterChecked] = useState(false);

    const handleCheckboxUpdates = () => {
        setIsUpdatesChecked(prevState => !prevState);
    };

    const handleCheckboxNewsletter = () => {
        setIsNewsletterChecked(prevState => !prevState);
    };

    const handleOptionSelection = (optionId) => {
        setSelectedOption(optionId);
    };

    const calculateTotal = () => {
        return lists.reduce((acc, list) => acc + parseFloat(list.value), 0).toFixed(2);
    };

    return (
        <div className="flex flex-col gap-5">
            <div className="space-y-4">
                <div className="space-y-6">
                    {options.map((option) => (
                        <div key={option.id} className="flex items-center gap-2 p-4 border rounded-lg border-neutral-400">
                            <input
                                type="radio"
                                id={`option-${option.id}`}
                                className="w-5 h-5 cursor-pointer form-radio text-neutral-400"
                                value={option.id}
                                checked={selectedOption === option.id}
                                onChange={() => handleOptionSelection(option.id)}
                            />
                            <label htmlFor={`option-${option.id}`} className="sr-only">{option.name}</label>
                            <div className="text-sm font-normal text-neutral-700">
                                {option.id === 1 ? (
                                    <span className="flex items-center gap-2">
                                        Custom Display name <Typography variant="authText" className="!font-medium text-sm text-neutral-1000" text={option.name} />
                                    </span>
                                ) : (
                                    <span>Display "{option.name}"</span>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex flex-col gap-4">
                    <Checkbox
                        className="check !text-sm"
                        label="Email me updates about this project"
                        checked={isUpdatesChecked}
                        onChange={handleCheckboxUpdates}
                        disabled=""
                    />
                    <Checkbox
                        className="check !text-sm"
                        label="Add me to the build the masjid newsletter"
                        checked={isNewsletterChecked}
                        onChange={handleCheckboxNewsletter}
                        disabled=""
                    />
                </div>
            </div>
            <div className="border-t border-neutral-400"></div>
            <div className="space-y-20">
                <div className="space-y-3">
                    <Typography variant="mediumText" className="font-bold text-neutral-1000" text="Tip Build the Masjid" />
                    <Typography variant="authText" className="text-neutral-700" text="Duis elementum lorem eu felis consequat elementum. Etiam vehicula suscipit ultrices. Etiam in orci et velit accumsan feugiat at non mi." />
                </div>
                <TipSelector />
            </div>
            <div className="space-y-4">
                <div className="space-y-3">
                    <Typography variant="mediumText" className="font-bold text-neutral-1000" text="Your Donation" />
                    <div className="space-y-4">
                        {lists.map((list) => (
                            <div key={list.name} className="flex items-center justify-between gap-5">
                                <Typography variant="authText" className="text-neutral-1000" text={list.name} />
                                <Typography variant="authText" className="!font-bold text-neutral-1000" text={`$${list.value}`} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="border-t border-neutral-400"></div>
                <div className="flex items-center justify-between gap-5">
                    <Typography variant="authText" className="text-neutral-1000" text="Total due Today" />
                    <Typography variant="authText" className="!font-bold text-neutral-1000" text={`$${calculateTotal()}`} />
                </div>
            </div>
        </div>
    )
}

export default PaymentOption;

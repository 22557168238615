import { AlertCircle } from "../Theme/icons";
import fileUpload from './fileUpload.js';

export const formatNumberIntoKMB = (n) => {
  if (n >= 1000000) {
    return (n / 1000000)?.toFixed(2) + "M";
  } else if (n >= 1000) {
    return (n / 1000)?.toFixed(0) + "k";
  } else {
    return n?.toString();
  }
};

export const calculateCollectionPercentage = (totalAmount, collectedAmount) => {
  if (totalAmount <= 0 || null) {
    return 0.0;
  }
  const collectionPercentage =
    (parseFloat(collectedAmount) / parseFloat(totalAmount)) * 100;

  return collectionPercentage > 100 && collectedAmount !== 0
    ? 100
    : collectionPercentage;
};

export const calculateProcessPercentageCharge = (amount) => {
  return (amount * 3) / 100;
};

export const ErrorMessage = ({ error, touched }) => (
  <>
    {touched && Boolean(error) && (
      <div className="flex items-center gap-1 mt-2 text-left text-red-300 text-caption-text">
        <AlertCircle className="mr-1" />
        {touched && error}
      </div>
    )}
  </>
);

export const uniqueElements = (array) => {
  const uniqueValues = [];
  const newSet = new Set();
  array?.forEach((ele) => {
    const id = Number(ele?.["id"])
    if (!newSet.has(id)) {
      uniqueValues.push(ele);
      newSet.add(id);
    }
  });
  return uniqueValues;
};

export const uniqueElementsWithKey = (array, key) => {
  const uniqueSet = new Set();
  const uniqueArray = array.filter(obj => {
    const keyValue = obj[key];
    if (uniqueSet.has(keyValue)) {
      return false;
    }
    uniqueSet.add(keyValue);
    return true;
  });

  return uniqueArray;
};

export const currencies = ['USD', 'AUD', 'GBP', 'CAD', 'EUR', 'AED'];


export const uploadMediaArr = async (arr) => {
  const toUpload = arr?.length && arr?.reduce((a, i) => i.file ? [...a, i.file] : a, []);
  let urls = [];
  if (toUpload?.length)
      urls = await fileUpload(toUpload);
  let ind = 0;
  const result = [];
  for (let i of arr) {
      if (i.file) {
          result.push({ url: urls[ind], type: i.type, isCover:i?.isCover });
          ind++;
      } else {
          result.push({ url: i.url, type: i.type ,isCover:i?.isCover });
      }
  }
  return result;
}
import React from 'react'
import propTypes from 'prop-types';

export const Skeleton = ({ className = "" }) => {
    return (<div className="animate-pulse w-full">
        <div className={` bg-secondary-200 rounded-sm ${className}`}></div>
    </div>)
}

Skeleton.propTypes = {
    className: propTypes.string
}


import { gql } from "@apollo/client";

const GET_PROJECT_OVERVIEW = gql`
  query GetProjectOverview($getProjectOverviewId: Int, $slug: String) {
    getProjectOverview(id: $getProjectOverviewId, slug: $slug) {
      project {
        id
        name
        slug
        description
        status
        goalAmount
        goalAmountCurrency
        latitude
        longitude
        rewards {
          id
          name
          amount
          status
          imageUrl
          description
        }
        organiserDetails {
          id
          firstName
          lastName
          displayName
          email
          role
          status
          profileImage
          defaultCurrency
        }
        ownerDetails {
          id
          firstName
          lastName
          displayName
          email
          role
          status
          profileImage
          defaultCurrency
        }
        launchDate
        city
        country
        zip
        isFeatured
        images {
          url
          isCover
        }
        createdAt
        totalDonatedAmount
        videoUrl
        quickBookClassId
        quickBookClassRef
      }
      projectUpdates {
        id
        status
        content
        images {
          url
          type
        }
        owner {
          firstName
          lastName
          displayName
          email
          profileImage
        }
        createdAt
      }
      recentPayments {
        id
        donationId
        updatedAt
        total
        conversionRate
        convertedAmount
        status
        Donation {
          id
          projectId
          stripePaymentId
          certificateUrl
          country
          zip
          amount
          amountCurrency
          convertedAmount
          conversionRate
          tip
          firstName
          lastName
          email
          display
          isAnonymous
          status
          isRecurring
          periodDays
          stripeSubscriptionId
          orderId
          paypalOrderId
          quickBookReceiptId
          paymentMethodId
          paymentGateway
          quantity
          total
          donatedAt
          createdAt
          donorDetails {
            id
            firstName
            lastName
            displayName
            email
            role
            status
            profileImage
            defaultCurrency
          }
        }
      }
      topPayments {
        id
        donationId
        updatedAt
        total
        conversionRate
        convertedAmount
        status
        Donation {
          id
          projectId
          stripePaymentId
          certificateUrl
          country
          zip
          amount
          amountCurrency
          convertedAmount
          conversionRate
          tip
          firstName
          lastName
          email
          display
          isAnonymous
          status
          isRecurring
          periodDays
          stripeSubscriptionId
          orderId
          paypalOrderId
          quickBookReceiptId
          paymentMethodId
          paymentGateway
          quantity
          total
          donatedAt
          createdAt
          donorDetails {
            id
            firstName
            lastName
            displayName
            email
            role
            status
            profileImage
            defaultCurrency
          }
          project {
            id
            name
            slug
            description
            status
            goalAmount
            goalAmountCurrency
            launchDate
            images {
              url
            }
            createdAt
          }
        }
      }
      totalDonationsThisWeek
      totalDonatedAmount
      totalDonors
      totalDonationsCount
    }
  }
`;

export default GET_PROJECT_OVERVIEW;

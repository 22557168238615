import React  from 'react'
import { Typography, Button } from '../../../../Components'
import { useFormik } from 'formik';
import * as yup from 'yup';
import { AlertCircle } from '../../../../Theme/icons';
 
const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    firstName: yup
        .string('Enter First name')
        .min(3, 'First name should be of minimum 3 characters length')
        .matches(/^[a-zA-Z0-9]*$/, 'Only alphabets and numbers are allowed')
        .required('First name is required'),
    lastName: yup
        .string('Enter Last name')
        .min(1, 'Last name should be of minimum 1 characters length')
        .matches(/^[a-zA-Z0-9\s]+$/, 'Only alphabets,numbers and spaces are allowed')
        .required('Last name is required'),
});

export const UnkownCheckout = ({ updateUnkownUserData, updateProjectOverviews=()=>null }) => {

    const formik = useFormik({
        enableReinitialize: false,
        initialValues: {
            email: '',
            firstName: '',
            lastName: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => updateUnkownUserData(values)
    });

    return (
        <div className='flex flex-col gap-5 p-5 border rounded-lg border-neutral-400'>
            <div className='pb-5 border-b border-neutral-400'>
                <Typography variant="mediumText" className="font-bold text-center text-neutral-800" text="Credit / Debit Card" />
            </div>
            <form onSubmit={formik.handleSubmit} className='space-y-5' autoComplete='off'>
                <div className='space-y-4'>
                    <div className='space-y-6'>
                        <div className='grid grid-cols-1 gap-6 sm:grid-cols-2'>
                            <div>
                                <label htmlFor="firstName" className="sr-only">First Name</label>
                                <input id='firstName' className='w-full input' type="text"
                                    value={formik.values.firstName} onBlur={formik.handleBlur} name="firstName"
                                    onChange={formik.handleChange}
                                    placeholder='First Name' />
                                {formik.touched.firstName && Boolean(formik.errors.firstName) && (
                                    <div className="flex items-center gap-1 mt-2 text-left text-red-300 text-caption-text">
                                        <AlertCircle className="mr-1" />
                                        {formik.errors.firstName}
                                    </div>
                                )}
                            </div>
                            <div>
                                <label htmlFor="lastName" className="sr-only">Last Name</label>
                                <input id='lastName' className='w-full input' type="text"
                                    value={formik.values.lastName} onBlur={formik.handleBlur} name="lastName"
                                    onChange={formik.handleChange} placeholder='Last Name' />
                                {formik.touched.lastName && Boolean(formik.errors.lastName) && (
                                    <div className="flex items-center gap-1 mt-2 text-left text-red-300 text-caption-text">
                                        <AlertCircle className="mr-1" />
                                        {formik.errors.lastName}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <label htmlFor="emailAddress" className="sr-only">Email Address</label>
                            <input id='emailAddress' value={formik.values.email} onBlur={formik.handleBlur} name="email"
                                onChange={formik.handleChange} className='w-full input' type="text" placeholder='Email Address' />
                            {formik.touched.email && Boolean(formik.errors.email) && (
                                <div className="flex items-center gap-1 mt-2 text-left text-red-300 text-caption-text">
                                    <AlertCircle className="mr-1" />
                                    {formik.errors.email}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Button
                    variant="primary"
                    label={"Next"}
                    type="submit"
                    iconClassName="hidden"
                    className="w-full"
                />

            </form>
        </div>
    )
}


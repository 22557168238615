import React, { lazy } from 'react';    

const AdminTemplate = lazy(() => import('../Layouts/AdminTemplate'));
const AdminLogin = lazy(() => import('../Pages/AdminPanel/Authentication/Login/index'));
const AdminForgotPassword = lazy(() => import('../Pages/AdminPanel/Authentication/ForgotPassword/index'));
const AdminResetPassword = lazy(() => import('../Pages/AdminPanel/Authentication/ResetPassword/index'))
const AdminVerifyAccount = lazy(() => import('../Pages/VerifyAdminAccount/index'))
const AdminProfile = lazy(() => import('../Pages/AdminPanel/AdminDashboard/AdminProfile/index'));
const AdminUsers = lazy(() => import('../Pages/AdminPanel/AdminDashboard/AdminUsers/index'));
const CustomerManagement = lazy(() => import('../Pages/AdminPanel/AdminDashboard/CustomerManagement/index'));
const CustomerDetails = lazy(() => import('../Pages/AdminPanel/AdminDashboard/CustomerManagement/CustomerDetails/index'));
const DonationManagement = lazy(() => import('../Pages/AdminPanel/AdminDashboard/DonationsManagement/index'));
const DonationDetails = lazy(() => import('../Pages/AdminPanel/AdminDashboard/DonationsManagement/DonationDetails/index'));
 

const adminRoutes = [
    {
        path: "login",
        element: <AdminLogin />,
        title: 'Admin Login',
        redirectAdmin: '/admin/profile',
        redirectUser: '/home'
    }, {
        path: "forgot-password",
        element: <AdminForgotPassword />,
        title: 'Admin Forgot Password',
        redirectAdmin: '/admin/profile',
        redirectUser: '/home'
    }, {
        path: "reset-password",
        element: <AdminResetPassword />,
        title: 'Admin Reset Password',
        redirectAdmin: '/admin/profile',
        redirectUser: '/home'
    }, {
        path: "verify-account",
        element: <AdminVerifyAccount />,
        title: 'home',
        redirectAdmin: '/admin/profile',
        redirectUser: '/home'
    },
   
    {
        path: "",
        element: <AdminTemplate />,
        redirectUser: '/home',
        redirectNoAuth: '/home',
        children: [
            {
                path: "",
                element: <></>,
                title: "Dashboard",
            },
            {
                path: "projects",
                element: <></> ,
                title: "Projects",
            },
            {
                path: "profile",
                element: <AdminProfile />,
                title: "Admin Profile",
            },
            {
                path: "users",
                element: <AdminUsers />,
                title: "Admin Users",
            },
            {
                path: "customers",
                element: <CustomerManagement />,
                title: "Customers",
            },
            {
                path: "customer-details",
                element: <CustomerDetails />,
                title: "Customer Details",
            },
            {
                path: "donations",
                element: <DonationManagement />,
                title: "Donations Management",
            },
            {
                path: "donations/donation-details",
                element: <DonationDetails />,
                title: "Donations Details",
            },
        ],
    },
]
 

export default adminRoutes;

import React, { useState, memo} from "react";
import Autocomplete from "react-autocomplete";
import PropTypes from 'prop-types';

export const AutocompleteComponent = memo(({ suggestions, className, customTheme }) => {
  const [value, setValue] = useState("");

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onSelect = (value) => {
    setValue(value);
  };

  const renderItem = (item, isHighlighted) => (
    <div style={{ background: isHighlighted ? "lightgray" : "white" }}>
      {item}
    </div>
  );

  const renderMenu = (items, style) => (
    <div style={{ ...style }}>
      {items}
    </div>
  );

  return (
    <Autocomplete
      getItemValue={(item) => item}
      items={suggestions}
      renderItem={renderItem}
      renderMenu={renderMenu}
      value={value}
      onChange={onChange}
      onSelect={onSelect}
      inputProps={{
        className: `border border-gray-300 py-2 px-4 w-80 ${className}`,
      }}
      theme={customTheme}
    />
  );
});

AutocompleteComponent.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  customTheme: PropTypes.object
};

import React from 'react'
import { Typography } from '../Typography'
import Button from '../Button'

export const DeleteModal = ({ setDeleteCardModal, handleRemoveElementFromCart, loading }) => {
    return (
        <div className="flex flex-col items-center gap-8">
            <div className="space-y-4 text-center">
                <Typography variant="heading6" className="text-neutral-1000" text="Delete Item" />
                <Typography variant="mediumText" className="text-neutral-800" text="Are you sure you want to delete this item from your cart?" />
            </div>
            <div className="flex items-center w-full gap-4">
                <Button
                    loader={false}
                    loaderText=""
                    label="Cancel"
                    iconClassName="hidden"
                    variant="primary-outline"
                    className="!w-1/2"
                    onClick={() => setDeleteCardModal(false)}
                />
                <Button
                    loader={loading}
                    loaderText=""
                    label="Yes"
                    iconClassName="hidden"
                    onClick={() => handleRemoveElementFromCart()}
                    variant="primary"
                    className="!w-1/2 border border-primary-300"

                />
            </div>
        </div>
    )
}


import { client } from './ApolloProvider';
import { GET_UPLOAD_LINK } from '../GraphQL/Queries/fileUpload';
import axios from 'axios';

const uploadToS3 = async(file, data) => {
    const {url, fields, key} = data;
    const fieldsData = JSON.parse(fields)
    const fd = new FormData();
    for(let i in fieldsData) {
        fd.append(i.toLowerCase(), fieldsData[i]);
    }
    fd.append('file', file, file.name);
    try {
        await axios.post(url, fd)
        return url+(url.endsWith('/')?'':'/')+key;
    } catch (error) {
        console.error(error);
        throw new Error('Something went wrong');
    }
}

export default async function uploadFiles(files) {
    const res = await client.query({
        query: GET_UPLOAD_LINK,
        variables: {
            files: files.map(i => ({
                filename: i.name,
                mimetype: i.type
            }))
        },
        fetchPolicy: 'network-only'
    })
    const {data: {getUploadLink: data}, error} = res;
    if(error)
        throw new Error(error.message);
    const ret = await Promise.allSettled(data.fileFields.map((i, ii) => uploadToS3(files[ii], i)))
    return ret.map(i => i.reason || i.value);
}

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../Button';
import { MyCart } from '../../Theme/icons';
import { useLazyQuery, useMutation, } from '@apollo/client';
import { GET_CART_ITEMS, UPDATE_CART_ITEMS } from '../../GraphQL/Queries/checkout';
import { updateCartItems } from '../../Redux/cartReducer';
import { uniqueElementsWithKey } from '../../Utils/common';

const CartIcon = React.memo(({ onClick, className, count }) => {
    const dispatch = useDispatch();
    const [addItemsintoBasketAPI] = useMutation(UPDATE_CART_ITEMS)
    const { cartItems } = useSelector(state => state?.cart) || { cartItems: [] }
    const { user = null } = useSelector(state => state?.auth) || { user: null }
    const [getCartItems, { loading }] = useLazyQuery(GET_CART_ITEMS, { fetchPolicy: "cache-and-network" })

    const addItemstoBasket = async (nonSavedItems) => {
        try {
            const payload = (!!nonSavedItems?.length && nonSavedItems?.map((item) => ({
                amount: parseInt(item.amount),
                country: item.country,
                type: item.type,
                isRecurring: item?.isRecurring,
                projectId: String(item.projectId),
                amountCurrency: item.amountCurrency,
                quantity: 1,
                periodDays: item?.isRecurring ? item?.periodDays : 1
            }))) || []
            const { data } = await addItemsintoBasketAPI({ variables: { basketItems: payload } })
            const { insertAllIntoBasket: { basketItems = [] } = {} } = data;
            
            return { success: Boolean(data), response: basketItems }
        } catch (error) {
            return { success: false }
        }
    }

    const getBAsketFilteredArray = (items) => (!!items?.length && items?.map(basketItem => ({
        ...basketItem,
        ...basketItem?.project,
        projectId: String(basketItem?.id),
        amount: parseFloat(basketItem?.amount),
        currency: basketItem?.amountCurrency,

    }))) || []

    const getBasketsFromAPI = async () => {
        try {

            const { data: { getBasketItems = [] } = {} } = await getCartItems({});

            const apilistedItems = getBAsketFilteredArray(getBasketItems) || [];

            const apiListedItemsPorjectId = (!!apilistedItems?.length && apilistedItems?.map(list => String(list?.id))) || []

            const cartsFromLocalStorage = getBAsketFilteredArray(JSON.parse(localStorage.getItem("cart") || "[]")) || [];

            const localStorageItemsonly = (!!cartsFromLocalStorage?.length && cartsFromLocalStorage?.filter(cart => !apiListedItemsPorjectId?.includes(String(cart?.id)))) || []

            if (localStorageItemsonly?.length) {
                const { success, response = [] } = await addItemstoBasket(localStorageItemsonly);
                const _responce = !!response?.length && response?.map((response) => ({
                    ...response,
                    ...(localStorageItemsonly?.length &&
                        localStorageItemsonly?.find(storage => String(storage?.projectId) === String(response?.projectId)))
                }))

                if (success) { localStorage.removeItem("cart") }
                const sumWithUniqueProjectId = await uniqueElementsWithKey([...apilistedItems, ...getBAsketFilteredArray(_responce)], "id") || [];
                dispatch(updateCartItems(sumWithUniqueProjectId))
            } else {
                const sumWithUniqueProjectId = await uniqueElementsWithKey(apilistedItems, "id")
                dispatch(updateCartItems(sumWithUniqueProjectId))
            }

        } catch (error) {
            console.log(error, "error");
        }
    }


    useEffect(() => {
        if (user) {
            getBasketsFromAPI();
        }
    }, [user])


    if (loading) {
        return
    }

    return (
        <div className={`text-center relative ${className}`}>
            <Button
                variant="primary-text"
                id="cart-button"
                label=""
                icon={<MyCart />}
                iconClassName=""
                labelClassName="hidden"
                className="!p-0 w-fit hover:!bg-transparent"
                onClick={onClick}
            />
            {cartItems?.length > 0 && (
                <div className="absolute inline-flex items-center justify-center w-5 h-5 font-bold text-white bg-red-300 rounded-full text-[10px] -top-2 -end-2">
                    {cartItems?.length}
                </div>
            )}
        </div>
    );
});

CartIcon.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    count: PropTypes.number,
};

CartIcon.defaultProps = {
    onClick: () => { },
    className: '',
    count: 2,
};

export default CartIcon;

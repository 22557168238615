import React from 'react'
import { Navigate, useParams } from 'react-router-dom';

const CheckRouteParams = ({ children = <></> }) => {
    const { projectid } = useParams();
    return (
        <>
            {!projectid ? <Navigate to={"/not-found"} /> : children}
        </>
    )
}

export default CheckRouteParams
